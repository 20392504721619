//import global API
import Api from '../../api/Api'

const cart = {

    //set namespace true
    namespaced: true,

    //state
    state: {
    
        //cart
        cart: [],

        //total cart
        cartTotal: 0,

        //cart weight
        cartWeight: 0,

        //status get dari server false -> belum mengambil data
        cartStatus: false

    },

    //mutations
    mutations: {

        //get data cart
        GET_CART(state, product) {
            state.cart = product
            state.cartStatus = true
        },

        //get total cart
        TOTAL_CART(state, total) {
            state.cartTotal = total
        },

        //get cart weight
        CART_WEIGHT(state, weight) {
            state.cartWeight = weight
        },

        //clear all cart
        CLEAR_CART(state) {
            state.cart       = []
            state.cartTotal  = 0
            state.cartWeight = 0
            state.cartStatus = false
        }

    },

    //actions
    actions: {

        //action addToCart
        addToCart({ dispatch }, payload) {
            
            //get data token dan user
            const token = localStorage.getItem('token')
            const user  = JSON.parse(localStorage.getItem('user'))
            
            return new Promise((resolve, reject) => {
                //send data cart ke server
                var data = {
                    product_id: payload.product_id,
                    qty: payload.qty ? payload.qty : 1
                }

                if(payload.variant_id != '') {
                    data.variant_id = payload.variant_id
                }

                Api.post(`/cart`, data, {
                    headers: {
                        'X-DOMAIN-MEMBER': user.domain,
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {

                    //get dat cart
                    dispatch('refreshCart')

                    resolve(response)

                })
                .catch(error => {
                    console.log(error)

                    //reject ke component dengan hasil response
                    reject(error.response.data)

                })
            })
        },

        refreshCart({ commit }) {
            const token = localStorage.getItem('token')
            const user  = JSON.parse(localStorage.getItem('user'))

            if(!user) {
                return false
            }

            Api.get('/cart', {
                headers: {
                    'X-DOMAIN-MEMBER': user.domain,
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                
                //commit mutation GET_CART
                commit('GET_CART', response.data.data.cart)
                commit('TOTAL_CART', response.data.data.total)

            })
        },

        //remove cart
        removeCart({dispatch}, payload) {
            
            const token = localStorage.getItem('token')
            const user  = JSON.parse(localStorage.getItem('user'))

            return new Promise((resolve, reject) => {
                Api.delete(`/cart/${payload.cart_id}`, {
                    headers: {
                        'X-DOMAIN-MEMBER': user.domain,
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    dispatch('refreshCart')

                    resolve(response)
                })
                .catch(error => {

                    console.log(error)

                    //reject ke component dengan hasil response
                    reject(error.response.data)

                })
            })

        },

        //update cart qty
        updateCart({dispatch}, payload) {
            
            const token = localStorage.getItem('token')
            const user  = JSON.parse(localStorage.getItem('user'))

            var data = {
                product_id: payload.product_id,
                type: payload.type,
                note: payload.note,
            }

            if(payload.status) {
                data.status = true
            }

            return new Promise((resolve, reject) => {
                Api.post(`/cart/qty-update/${ payload.cart_id }`, data,{
                    headers: {
                        'X-DOMAIN-MEMBER': user.domain,
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    dispatch('refreshCart')

                    resolve(response)
                })
                .catch(error => {

                    //reject ke component dengan hasil response
                    reject(error.response.data)

                })
            })

        },

        //checkout
        checkout({ commit }, data) {

            return new Promise((resolve, reject) => {

                Api.post('/checkout', {
                    
                    courier:    data.courier_type,
                    service:    data.courier_service,
                    cost:       data.courier_cost,
                    weight:     data.weight,
                    name:       data.name,
                    phone:      data.phone,
                    province:   data.province_id,
                    city:       data.city_id,
                    address:    data.address,
                    grand_total:data.grandTotal

                })
                .then(response => {

                    resolve(response.data)

                    //remove all Cart  on database
                    Api.post('/cart/removeAll')
                    .then(() => {

                        //clear  cart
                        commit('CLEAR_CART')

                    })
                    .catch(error => {
                        console.log(error)
                    })

                })
                .catch(error => {
                    reject(error)
                })

            })

        }


    },

    //getters
    getters: {

        //get cart
        getCart(state) {
            return state.cart
        },
        
        //count cart
        cartCount(state) {
            var cart = state.cart
            var count = 0

            cart.forEach(item => {
                if(item.status) {
                    count = count + item.qty
                }
            });

            return count
        },
        
        //count cart
        cartTotalDiscount(state) {
            var cart = state.cart
            var discount = 0

            cart.forEach(item => {
                discount = discount + ((item.product.price * item.qty) - item.price)
            });

            return discount
        },

        //cart total
        cartTotal(state) {
            return state.cartTotal
        },

        //cart status
        cartStatus(state) {
            return state.cartStatus
        }

    }

}

export default cart