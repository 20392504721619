<template>
  <div class="container py-5">
		<div class="row">
			<div class="col-md-4 px-4 px-md-3">
				<template v-if="have_subdomain">
					<template v-if="config.reseller">
						<img :src="config.reseller.logo" v-if="have_subdomain && config.reseller.logo" class="footer-logo">
						<span class="store-name footer" v-else>
							{{ config.reseller.store_name }}
						</span>
					</template>
				</template>
				<template v-else>
					<img :src="config.theme.logo" class="footer-logo">
				</template>
				
				<p class="mt-3 font-10">
					{{ config.theme.footer }}
				</p>
				<p class="font-weight-bold">
					Follow Us On
				</p>
				<div class="d-flex flex-wrap" v-if="config.socmed">
					<a :href="'https://www.facebook.com/' + config.socmed.facebook.username" class="btn p-3" v-if="config.socmed.facebook.status" target="_blank">
						<img :src="Facebook">
					</a>
					<a :href="'https://www.instagram.com/' + config.socmed.instagram.username" class="btn p-3" v-if="config.socmed.instagram.status" target="_blank">
						<img :src="Instagram">
					</a>
					<a :href="'https://youtube.com/' + config.socmed.youtube.username" class="btn p-3" v-if="config.socmed.youtube.status" target="_blank">
						<img :src="Youtube">	
					</a>
					<a :href="'https://www.tiktok.com/@' + config.socmed.tiktok.username" class="btn p-3" v-if="config.socmed.tiktok.status" target="_blank">
						<img :src="Tiktok" height="18">
					</a>
					<a :href="'https://www.tokopedia.com/' + config.socmed.tokopedia.username" class="btn p-3" v-if="config.socmed.tokopedia.status" target="_blank">
						<img :src="Tokopedia" height="22">
					</a>
					<a :href="'https://shopee.com/' + config.socmed.shopee.username" class="btn p-3" v-if="config.socmed.shopee.status" target="_blank">
						<img :src="Shopee" height="18">	
					</a>
				</div>
			</div>
			<div class="col-md-4 px-4 mt-4 mt-md-0">
				<h5>Payment Methods</h5>
				<a href="#" class="btn bg-grey p-3 me-2 mb-2" style="max-width: 120px" v-for="bank in config.payment" :key="'payment' + bank.id">
					<img :src="bank.bank.logo" class="w-100">
				</a>
			</div>
			<div class="col-md-2 px-4 px-md-3 mt-4 mt-md-0">
				<h5>
					Selling
				</h5>
				<router-link :to="{name: 'reseller-login'}" class="my-2 footer-nav-link">
					Login Reseller
				</router-link><br/>
				<router-link :to="{name: 'reseller-register'}" class="my-2 footer-nav-link">
					Register Reseller
				</router-link>
			</div>
			<div class="col-md-2 px-4 px-md-3 mt-4 mt-md-0">
				<h5>
					Store Menu
				</h5>
				<router-link :to="{name: 'home'}" class="my-2 footer-nav-link">
					Home
				</router-link><br/>
				<router-link :to="{name: 'about'}" class="my-2 footer-nav-link">
					About
				</router-link><br/>
				<router-link :to="{name: 'blog'}" class="my-2 footer-nav-link" v-if="config.theme.blog">
					Blog
				</router-link><br/>
				<router-link :to="{name: 'contact'}" class="my-2 footer-nav-link" v-if="config.theme.contact">
					Contact
				</router-link><br/>
				<router-link :to="{name: 'term-and-condition'}" class="my-2 footer-nav-link">
					Term and Conditions
				</router-link><br/>
				<router-link :to="{name: 'faq'}" class="my-2 footer-nav-link" v-if="config.theme.faq">
					FAQ
				</router-link><br/>
			</div>
			<div class="col-md-12 py-4 px-4 px-md-3">
				<h6>
					&copy; {{ config.theme.title }} 2021
				</h6>
			</div>
		</div>
	</div>
</template>

<script>
	import Logo from '../assets/global/logo-blue.svg';
	import Facebook from '../assets/icons/facebook.svg';
	import Youtube from '../assets/icons/youtube.png';
	import Instagram from '../assets/icons/instagram.svg';
	import Tokopedia from '../assets/icons/tokopedia.png';
	import Tiktok from '../assets/icons/tiktok.png';
	import Shopee from '../assets/icons/shopee.png';
	import BCA from '../assets/logo/bca.png';
	import BNI from '../assets/logo/bni.png';
	import BRI from '../assets/logo/bri.png';
	import Gopay from '../assets/logo/gopay.png';
	import Indomaret from '../assets/logo/indomaret.png';
	import AtmBersama from '../assets/logo/atm-bersama.png';

	export default {
		setup() {
			return{
				Logo: Logo,
				Facebook: Facebook,
				Youtube: Youtube,
				Instagram: Instagram,
				Tokopedia,
				Tiktok,
				Shopee,
				BCA: BCA,
				BNI: BNI,
				BRI: BRI,
				Gopay: Gopay,
				Indomaret: Indomaret,
				AtmBersama: AtmBersama
			}
		},
        computed: {
            config() {
                return this.$store.getters['config/getAllConfig']
            },
		},
		created() {
		}
	}
</script>

<style>
.footer-nav-link{
	font-weight: bold;
	font-size: 12px;
	line-height: 179.9%;
	color: #707070;
	text-decoration: none;
	transition: 0.5s;
}
.footer-nav-link:hover, .footer-nav-link:focus{
	font-weight: normal;
	font-size: 12px;
	line-height: 179.9%;
	color: #707070;
	text-decoration: none;
}

.footer-logo{
	max-height: 100px;
	max-width: 150px;
}

.font-10 {
	font-size: 10px;
}
</style>