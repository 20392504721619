import { createApp } from 'vue'
import App from './App.vue'

import * as moment from "moment/moment";

//import router
import router from './router'

//import store vuex
import store from './store'

//import dynamic layout
import AdminLayout from './layouts/AdminLayout.vue';
import CustomerLayout from './layouts/CustomerLayout.vue';
import ResellerLayout from './layouts/ResellerLayout.vue';
import ResellerPanelLayout from './layouts/ResellerPanelLayout.vue';
import BlankLayout from './layouts/BlankLayout.vue';

const app = createApp(App)

app.config.devtools = true

//dynamic layout
app.component('admin-layout', AdminLayout);
app.component('customer-layout', CustomerLayout);
app.component('reseller-layout', ResellerLayout);
app.component('reseller-panel-layout', ResellerPanelLayout);
app.component('default-layout', BlankLayout);

//gunakan router di vue js dengan plugin "use"
app.use(router)

//gunakan store di vue js dengan plugin "use"
app.use(store)


import { parse } from 'tldts';
import config from './store/module/config.js'

//get hostname
// production
var host = 'https://'+config.main_domain+'/'

// development
//var host = 'http://'+config.main_domain+'/'

var host_detail = parse(host)


const HelloJs = require('hellojs/dist/hello.all.min.js');

HelloJs.init(
    {
        google: config.fb_client_id,
        facebook: config.google_client_id,
    }, 
    {
        redirect_uri: `https://${ host_detail.hostname }/login/callback`,
        display: 'page'
    }
);

app.use(HelloJs)

//define mixins for global function
app.mixin({

    data() {
        return {
            domain: config.state.domain,
            // domain: 'kumi.id',
            have_subdomain: config.state.have_subdomain ? true : false
        }
    },
    methods: {

        //money thousands
        moneyFormat(number) {
            let reverse = number.toString().split('').reverse().join(''),
            thousands   = reverse.match(/\d{1,3}/g)
            thousands   = thousands.join('.').split('').reverse().join('')
            return thousands
        },
        
        date(date, format){
            return moment(date).format(format)
        },

        socialLogin(network, reseller) {
            if(reseller) {
                HelloJs(network).login({
                    scope: 'email',
                    redirect_uri: `https://${ host_detail.hostname }/reseller/login/callback`,
                })
                localStorage.setItem('login_reseller', true)
            }else{
                HelloJs(network).login({
                    scope: 'email'
                })
                localStorage.setItem('login_reseller', false)
            }
        },

        callbackLogin() {
            HelloJs.getAuthResponse()

            return new Promise((resolve) => {
                HelloJs.on('auth.login', function(auth) {

                    // Call user information, for the given network
                    HelloJs(auth.network).api('me').then(function(r) {
                        var data = {
                            network: auth.network,
                            response: r
                        }
                        // Inject it into the container
                        return resolve(data)
                    });
                });

            })
        },

        //calculate discount
        calculateDiscount(product) {
            if(product.discount_type == 'nominal') {
                return product.price - product.discount
            }else{
                return product.price - (product.price * (product.discount) / 100)
            }
        },
        showLoading() {
            // $('.loading').css('display', 'flex')
            document.getElementById("loading").style.display = 'flex'
        },
        closeLoading() {
            // $('.loading').css('display', 'none')
            document.getElementById("loading").style.display = 'none'
        },
        redirectNotification(item) {
            var user = JSON.parse(localStorage.getItem('user'))

            if(user) {
                if(user.login_as == 'admin') {
                    if(item.data.type == "new_order") {
                        this.$router.push({
                            name: 'admin-orders'
                        })
                    }else if(item.data.type == "order_paid") {
                        this.$router.push({
                            name: 'admin-orders'
                        })
                    }else if(item.data.type == "order_delivered") {
                        this.$router.push({
                            name: 'admin-order-delivered'
                        })
                    }else if(item.data.type == "request_withdraw") {
                        this.$router.push({
                            name: 'admin-wallet-mutasi-onprocess'
                        })
                    }else if(item.data.type == "approve_withdraw") {
                        this.$router.push({
                            name: 'admin-wallet-mutasi-onprocess'
                        })
                    }else if(item.data.type == "request_complaint") {
                        this.$router.push({
                            name: 'admin-order-complaint'
                        })
                    }else if(item.data.type == "approve_complaint") {
                        this.$router.push({
                            name: 'admin-order-complaint'
                        })
                    }else if(item.data.type == "discussion") {   
                        this.$router.push({
                            name: 'admin-products-show',
                            params: {
                                id: item.data.id,
                                tab: 'discussion'
                            }
                        })
                    }else if(item.data.type == "forum") {
                        this.$router.push({
                            name: 'admin-forum-detail', 
                            params: {
                                slug: item.data.slug
                            }
                        })
                    }
                } else if(user.login_as == 'reseller') {
                    if(item.data.type == "new_order") {
                        this.$router.push({
                            name: 'reseller-order-pay'
                        })
                    }else if(item.data.type == "order_paid") {
                        this.$router.push({
                            name: 'reseller-order-process'
                        })
                    }else if(item.data.type == "order_delivered") {
                        this.$router.push({
                            name: 'reseller-order-delivered'
                        })
                    }else if(item.data.type == "request_withdraw") {
                        this.$router.push({
                            name: 'reseller-wallet-mutasi-onprocess'
                        })
                    }else if(item.data.type == "approve_withdraw") {
                        this.$router.push({
                            name: 'reseller-wallet-mutasi-onprocess'
                        })
                    }else if(item.data.type == "request_complaint") {
                        this.$router.push({
                            name: 'reseller-sales-complaint'
                        })
                    }else if(item.data.type == "approve_complaint") {
                        this.$router.push({
                            name: 'reseller-sales-complaint'
                        })
                    }else if(item.data.type == "discussion") {   
                        this.$router.push({
                            name: 'reseller-product-show',
                            params: {
                                slug: item.data.slug
                            }
                        })
                    }else if(item.data.type == "forum") {
                        this.$router.push({
                            name: 'reseller-forum-detail', 
                            params: {
                                slug: item.data.slug
                            }
                        })
                    }
                } else {
                    if(item.data.type == "new_order") {
                        this.$router.push({
                            name: 'pay'
                        })
                    }else if(item.data.type == "order_paid") {
                        this.$router.push({
                            name: 'process'
                        })
                    }else if(item.data.type == "order_delivered") {
                        this.$router.push({
                            name: 'delivered'
                        })
                    }else if(item.data.type == "request_withdraw") {
                        this.$router.push({
                            name: 'customer-wallet-mutasi'
                        })
                    }else if(item.data.type == "approve_withdraw") {
                        this.$router.push({
                            name: 'customer-wallet-mutasi'
                        })
                    }else if(item.data.type == "request_complaint") {
                        this.$router.push({
                            name: 'complaint'
                        })
                    }else if(item.data.type == "approve_complaint") {
                        this.$router.push({
                            name: 'complaint'
                        })
                    }else if(item.data.type == "discussion") {   
                        this.$router.push({
                            name: 'product',
                            params: {
                                slug: item.data.slug
                            }
                        })
                    }else if(item.data.type == "order_refund") {
                        this.$router.push({
                            name: 'refund',
                            params: {
                                slug: item.data.slug
                            }
                        })
                    }
                }
            }
        }

    }
})

app.mount('#app')