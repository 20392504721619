<template>
    <div class="customer-layout">
        <Header />
        <template v-if="token">
            <div class="spaces" v-if="!user.verified">
                <AlertVerification
                    buttonText="Verifikasi"
                    :buttonFunc="sendVerifyEmail"
                    :isSent="is_sent"
                    class="customer-alert"
                >
                    Your email has not been verified, please verify so you can continue purchasing
                </AlertVerification>
            </div>
        </template>
        <router-view />
        <Footer />
    </div>
</template>

<script>
import Header from '../components/HeaderCustomer.vue'
import Footer from '../components/FooterCustomer.vue'
import AlertVerification from '../components/AlertVerification.vue'
import * as moment from "moment/moment";
import Api from '../api/Api'
import Swal from 'sweetalert2'
import $ from 'jquery'

export default {
    components: {
        Header,
        Footer,
        AlertVerification,
    },
    data() {
        return{
            key: '',
            is_sent: false,
        }
    },
    computed: {
        user() {
            var user = this.$store.getters['auth/currentUser']
            return user
        },
        token() {
            return this.$store.getters['auth/isLoggedIn']
        },
    },
    created() {
        var date = moment().format('DDMMYYYYHH')

        this.key = 'kumi_verify_attempt' + this.user.email + date

        if(localStorage.getItem(this.key) != null) {
            this.is_sent = true
        }
    },
    methods: {
        sendVerifyEmail() {
            if(localStorage.getItem(this.key) != null) {
                Swal.fire({
                    title: 'Success',
                    text: 'Link verifikasi sudah dikirim, silahkan periksa email spam apabila tidak ada.',
                    icon: 'success',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: 'Close',
                    padding: '2rem',
                })
                return false
            }

            this.showLoading()

            Api.post(`email/verification-notification`, '', {
                headers: {
                    'X-DOMAIN-MEMBER': this.domain,
                    'Authorization': `Bearer ${this.token}`,
                }
            })
            .then(() => {
                this.is_sent = true
                localStorage.setItem(this.key, true)
                Swal.fire({
                    title: 'Success',
                    text: 'Link verifikasi sudah dikirim, silahkan periksa email spam apabila tidak ada.',
                    icon: 'success',
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: 'Close',
                    padding: '2rem',
                })

                this.closeLoading()
            })
            .catch((err) => {
                if (typeof err.response.data.message == 'object') {
                    Swal.fire({
                        title: 'FAILED!',
                        text: Object.values($(err.response.data.message).first().toArray()[0])[0][0],
                        icon: 'error',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: 'Close',
                        padding: '2rem',
                    })
                } else {
                    Swal.fire({
                        title: 'FAILED!',
                        text: err.response.data.message,
                        icon: 'error',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: 'Close',
                        padding: '2rem',
                    })
                }

                this.closeLoading()
            })
        }
    }
}
</script>

<style lang="scss">
    .spaces{
        height: 80px;
        width: 100%;
        position: relative;
    }
    .customer-alert{
        top: 104px;
    }
</style>