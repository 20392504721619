//import axios
import axios from 'axios'
import store from '../store'
import router from '../router'

var Api = axios.create({
    //set default endpoint API
    baseURL: process.env.VUE_APP_PROTOCOL+'://'+process.env.VUE_APP_API_DOMAIN+'/api',

    headers: {
        Accept : 'application/json'
    }
})

Api.interceptors.response.use((response) => response, (error) => {
    if(error.response) {
        if(error.response.status == 401 && error.response.data.message !== 'Invalid Credential!') {
            store.dispatch('auth/logout')
                .then(() => {
                    //redirect ke login
                    router.push({
                        name: 'login'
                    })
                }).catch(error => {
                    //assign validaation message
                    console.log(error)
                })
        }
    }
    throw error;
});

export default Api