<template>
    <div class="alert-ribbon danger">
        <div class="d-flex align-items-center">
            <div class="ps-1 pe-3">
                <img src="../assets/icons/alert.png" alt="alert-icon" width="24">
            </div>
            <div class="text text-left">
                <slot></slot>
            </div>
            <template v-if="isSent">
                <div class="ms-auto pl-4">
                    <button class="btn btn-danger btn-sm" disabled>
                        Email Sent
                    </button>
                </div>
            </template>
            <template v-else>
                <div class="ms-auto pl-4" v-if="buttonFunc">
                    <button class="btn btn-danger btn-sm" @click="doFunc()">
                        {{ buttonText }}
                    </button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        buttonFunc: {
            type: Function
        },
        buttonText: {
            type: String
        },
        isSent: {
            type: Boolean
        },
    },
    methods: {
        doFunc() {
            this.buttonFunc()
        }
    }
}
</script>

<style lang="scss">
    .alert-ribbon{
        background-color: #f5dcdf;
        padding: 1rem;
        border-radius: 1rem;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 3rem);
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        .text{
            font-weight: 600;
            color: #5F1616;
        }
    }
</style>