<template>
  <div>
    <Header/>
    <div class="reseller-page bg-light">
      <div class="container-fluid px-lg-5 px-md-4">
        <div class="reseller-content">
          <div class="left-side">
            <Sidebar/>
          </div>
          <div class="right-side">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from '../components/HeaderAdmin.vue'
  import Sidebar from '../components/SidebarAdmin.vue'

  export default {
    components : {
      Header,
      Sidebar
    },
  }
</script>

<style lang="scss" scoped>
  .reseller-page{
    padding-top: 102px;
    padding-bottom: 64px;
  }
  .reseller-content{
    display: flex;
    .left-side{
      width: 280px;
      height: auto;
    }
    .right-side{
      width: calc(100% - 280px);
      padding-left: 1.5rem;
    }
  }
  @media screen and (max-width: 768px){
    .reseller-page{
      padding-top: 65px;
    }
    .reseller-content{
      display: block;
      .left-side{
        width: 100%;
      }
      .right-side{
        width: 100%;
        padding-left: 0;
      }
    }
  }
</style>