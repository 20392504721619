<template>
    <div>
        <div class="loading" id="loading">
            <img :src="config.theme.loader" alt="loader image" class="loading-image" v-if="config.theme.loader != '' || config.theme.loader != null">
            <img :src="Loader" alt="loader image" class="loading-image" v-else>
        </div>
        <component :is="layout" />
    </div>
</template>

<script scoped>
    import 'bootstrap/dist/css/bootstrap.min.css'
    import 'bootstrap/dist/js/bootstrap.min.js'
    import 'admin-lte/plugins/fontawesome-free/css/all.min.css'
    import 'jquery/dist/jquery.min.js'
    import './assets/js/summernote-lite.js'
    import './assets/css/summernote-lite.css'
    import $ from 'jquery'
    import Loader from './assets/global/loading-buffering.gif';
    import Api from './api/Api';

    const HelloJs = require('hellojs/dist/hello.all.min.js');

    const layout = 'default';

    export default {
        name: 'App',
        setup() {
            return{
                Loader
            }
        },
        data() {
            return {
            }
        },
        computed: {
            layout() {
                require('./assets/app.css');
                return (this.$route.meta.layout || layout) + '-layout'
            },
            config() {
                return this.$store.getters['config/getAllConfig']
            },
        },
        watch: {
            'config.meta': function() {
                var html = `
                    <meta name="description" content="${ this.config.meta.meta_description }">
                    <meta name="keywords" content="${ this.config.meta.meta_keyword }">
                    <title>${ this.config.meta.meta_title ? this.config.meta.meta_title : this.config.meta.title }</title>
                `
                $('head').prepend(html)
            },
            'config.analytics': function() {
                $(`${ this.config.analytics.analytic.replace(/\\/g, '')  }`).appendTo(document.head);
            },
            'config.theme': function() {
                var favicon = `<link rel="icon" type="image/x-icon" href="${ this.config.theme.favicon }">`
                $('head').prepend(favicon)
                // if(this.$route.meta.layout == 'customer') {
                    if(!this.have_subdomain) {
                        this.setThemeConfig()
                    }
                // }
            },
            'config.reseller': function() {
                this.setThemeConfig()
            },
            '$route.meta.layout': function() {
                // if(this.$route.meta.layout == 'customer') {
                    if(!this.have_subdomain) {
                        this.setThemeConfig()
                    }
                // }
            },
        },
        created() {
            this.$store.dispatch('config/getAllConfig')
            
            if(!this.token) {
                var self = this

                HelloJs.on('auth.login', function(auth) {

                    // Call user information, for the given network
                    HelloJs(auth.network).api('me').then(function(res) {
                        
                        // Inject it into the container
                        if(res) {
                            var data = {
                                name: res.name,
                                email: res.email,
                                provider_id: res.sub ? res.sub : res.id
                            }

                            // console.log(data, auth.network)
                            // console.log(self)

                            var reseller_login = localStorage.getItem('login_reseller')

                            if(reseller_login) {
                                self.callbackLoginReseller(data, auth.network)
                            } else {
                                self.callbackLogin(data, auth.network)
                            }
    
                        }

                    });
                });
            }
        },
        methods: {
            setThemeConfig() {
                var color = this.config.theme.theme_color
                var header_color = this.config.theme.header_color

                if(this.have_subdomain) {
                    color = this.config.reseller.color
                    header_color = this.config.reseller.color
                }

                if(color) {
                    var css = `
                        <style>
                            .customer-layout :root {
                                --blue: ${ color } !important;
                            }
    
                            .customer-layout .btn-blue{
                                background-color: ${ color } !important;
                                border: solid 2px ${ color } !important;
                            }
                            .customer-layout .btn-outline-blue{
                                border: solid 2px ${ color } !important;
                                color: ${ color } !important;
                            }
                            .customer-layout .mySwiper .swiper-slide-thumb-active{
                                border: solid 4px ${ color } !important;
                            }
                            .customer-layout .nav-link.active{
                                color: ${ color } !important;
                            }
                            .customer-layout a{
                                color: ${ color };
                            }
                            .customer-layout .text-blue{
                                color: ${ color } !important;
                            }
                            .customer-layout .nav-item.active{
                                border-bottom: solid 5px ${ color } !important;
                            }
                            .customer-layout .nav-item.active .link-item{
                                color: ${ color } !important;
                            }
                            .customer-layout .app-navbar{
                                background-color: ${ header_color } !important;
                            }
                            .customer-layout .app-topbar{
                                background-color: ${ header_color } !important;
                                filter: brightness(90%) !important;
                            }
                            .customer-layout .btn-white{
                                color: ${ color } !important;
                            }
                            .customer-layout .sidebar-text-inner.active{
                                color: ${ color } !important;
                            }
                            .customer-layout .user-image-initial{
                                border: 1px solid ${ color } !important;
                            }
                            .customer-layout .account-header-logo{
                                filter: grayscale(1) brightness(150%);
                            }
                            .customer-layout .icon-button{
                                filter: grayscale(1) brightness(100%);
                            }
                            .customer-layout .breadcumb-profile{
                                color: ${ color } !important;
                            }
                            .customer-layout .form-check-input:checked{
                                background-color: ${ color } !important;
                                border-color: ${ color } !important;
                            }
                            .customer-layout .upload-container{
                                background: var(--grey) !important;
                            }
                            .customer-layout .page-item.active .page-link{
                                background-color: ${ color } !important;
                            }
                            .customer-layout .store-name.footer{
                                color: ${ color } !important;
                            }
                            .customer-layout .step.active .number{
                                border: solid 2px ${ color } !important;
                                color: ${ color } !important;
                            }
                            .customer-layout .step.active .text{
                                color: ${ color } !important;
                            }
                            .customer-layout .step.done .number {
                                border: solid 2px ${ color } !important;
                                background-color: ${ color } !important;
                                color: white !important;
                            }
                            .customer-layout .step.done .text {
                                color: ${ color } !important;
                            }
                            .notfound.custom .contact-btn {
                                border: 2px solid ${ color } !important;
                                color: ${ color } !important;
                            }
                            .notfound.custom .homr-btn {
                                background: ${ color } !important;
                            }

                            .customer-layout .variant-option.selected{
                                background: ${ color } !important;
                                border: solid 2px ${ color } !important;
                            }

                            .customer-layout .variant-option:hover{
                                border: solid 2px ${ color } !important;
                            }

                        </style>
                    `
                    $('head').prepend(css)
                }
            },
            callbackLogin(data, network) {
                Api.post(`customer/${ network }/callback`, data, {
                    headers:{
                        'X-DOMAIN-MEMBER': this.domain,
                    }
                })
                .then((res) => {
                    var data = res.data.data

                    //Set Auth Token To AUTH js 
                    this.$store.dispatch('auth/callbackLogin', {
                        data: data,
                        login_as: 'customer'
                    })
                    .then(() => {
                        //redirect ke dashboard
                        this.clearSessionLogin()
                        window.location = this.$router.resolve({name: 'edit-profile'}).fullPath
                    }).catch(() => {
                        //Error Alert
                        window.location = this.$router.resolve({name: 'login'}).fullPath
                    })
                })
                .catch(() => {
                    this.clearSessionLogin()
                    window.location = this.$router.resolve({name: 'login'}).fullPath
                })
            },
            callbackLoginReseller(data, network) {
                Api.post(`reseller/${ network }/callback`, data, {
                    headers:{
                        'X-DOMAIN-MEMBER': this.domain,
                    }
                })
                .then((res) => {
                    var data = res.data.data

                    //Set Auth Token To AUTH js 
                    this.$store.dispatch('auth/callbackLogin', {
                        data: data,
                        login_as: 'reseller'
                    })
                    .then(() => {
                        //redirect ke dashboard
                        this.clearSessionLogin()
                        window.location = this.$router.resolve({name: 'reseller-edit-profile'}).fullPath
                    }).catch(() => {
                        //Error Alert
                        window.location = this.$router.resolve({name: 'reseller-login'}).fullPath
                    })
                })
                .catch(() => {
                    this.clearSessionLogin()
                    window.location = this.$router.resolve({name: 'reseller-login'}).fullPath
                })
            },
            clearSessionLogin() {
                localStorage.removeItem('hello')
                localStorage.removeItem('login_reseller')
            }
        }
    }
</script>

<style lang="scss">
    
    .note-editable { 
        p {
            font-size: 15px !important; 
            text-align: left !important;
        }
    }
    
    .loading{
        height: 100%;
        width: 100%;
        background-color: white;
        display: none;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3000;
        text-align: center;
        .loading-image{
            max-height: 75px;
            max-width: 75px;
            align-self: center;
        }
    }
    .form-control:focus {
        border: 1px solid #ced4da;
        border-radius: .25rem;
    }

    .form-control:hover {
        border-radius: .25rem;
    }

    .form-file:hover {
        background: #FFFFFF;
        border: 1px solid #bedfdf;
    }

    .form-check-input {
        font-size: 1.1rem !important;
    }

    .form-check-label {
        font-size: 0.9rem !important;
    }
</style>