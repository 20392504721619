import Api from '../../api/Api'

import { parse } from 'tldts';

var main_domain = process.env.VUE_APP_MAIN_DOMAIN
var api_domain = process.env.VUE_APP_API_DOMAIN
var host = window.location.href
if(process.env.VUE_APP_NODE_ENV !== 'production'){
    host = process.env.VUE_APP_PROTOCOL+'://'+process.env.VUE_APP_HOST+'/'
}
var fb_client_id = process.env.VUE_APP_FB_CLIENT_ID
var google_client_id = process.env.VUE_APP_GOOGLE_CLIENT_ID

var host_detail = parse(host)
console.log('host_detail: ' + host_detail.hostname + ' main_domain: ' + main_domain)
const config = {
    main_domain: main_domain,
    api_domain: api_domain,
    fb_client_id: fb_client_id,
    google_client_id: google_client_id,
    social_login: main_domain == host_detail.hostname ? true : false,
    is_production: window.location.href == host,

    namespaced: true,

    state: {
        config: {
            analytics: {
                config_id: "",
                analytic: ""
            },
            meta: {
                config_id: "",
                meta_title: "",
                meta_description: "",
                meta_keyword: ""
            },
            theme: {
                config_id: "",
                blog: true,
                contact: true,
                faq: true,
                title: "",
                theme_color: "",
                header_color: "",
                footer: "",
                store_description: "",
                widget_code: "",
                favicon: "",
                loader: "",
                error_banner: "",
                logo: "",
                invoice: ""
            },
            tnc: {
                config_id: "",
                content : ""
            },
            sliders: {
                config_id: "",
                status: true,
                sliders: []
            },
            landing: {
                config_id: "",
                status: false,
                html: "",
                css: "",
                js: "",
                meta_title: "",
                meta_description: "",
                meta_keyword: ""
            },
            payment: [],
            socmed: null,
            categories: []
        },
        domain: host_detail.hostname,
        // domain: 'odditycoffee.com',
        have_subdomain: host_detail.subdomain ? true : false
    },

    mutations: {
        SET_CONFIG(state, payload) {
            state.config[payload.key] = payload.data
        },
        ALL_CONFIG(state, payload) {
            state.config = payload
        },
    },

    actions: {
        getAllConfig({ commit, state, dispatch }) {
            var params = {
                headers: {
                    'X-DOMAIN-MEMBER': state.domain
                }
            }

            // const d = new Date();

            // var current_date = d.getFullYear() + d.getMonth() + d.getDate()

            // var key = state.domain + '-config-' + current_date

            // var storage = JSON.parse(localStorage.getItem(key))

            // if(!storage) {

                if(state.have_subdomain) {
                    dispatch('getResellerConfig')
                }

                Api.get(`config/meta`, params)
                .then((res) => {
                        var data = res.data.data
                        
                        var payload = {
                            key: 'meta',
                            data: data
                        }
    
                        commit('SET_CONFIG', payload)

                        // localStorage.setItem(key, JSON.stringify(state.config))
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    
                Api.get(`config/analytics`, params)
                    .then((res) => {
                        var data = res.data.data
                        
                        var payload = {
                            key: 'analytics',
                            data: data
                        }
    
                        commit('SET_CONFIG', payload)

                        // localStorage.setItem(key, JSON.stringify(state.config))
                    })
                    .catch((err) => {
                        console.log(err)
                    })

                Api.get(`config/theme`, params)
                    .then((res) => {
                        var data = res.data.data
                        
                        var payload = {
                            key: 'theme',
                            data: data
                        }
    
                        commit('SET_CONFIG', payload)

                        // localStorage.setItem(key, JSON.stringify(state.config))
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    

                Api.get(`config/tnc`, params)
                    .then((res) => {
                        var data = res.data.data
                        
                        var payload = {
                            key: 'tnc',
                            data: data
                        }
    
                        commit('SET_CONFIG', payload)

                        // localStorage.setItem(key, JSON.stringify(state.config))
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    
                    
                Api.get(`config/sliders`, params)
                    .then((res) => {
                        var data = res.data.data
                        
                        var payload = {
                            key: 'sliders',
                            data: data
                        }
    
                        commit('SET_CONFIG', payload)

                        // localStorage.setItem(key, JSON.stringify(state.config))
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    
                Api.get(`config/landing`, params)
                    .then((res) => {
                        var data = res.data.data
                        
                        var payload = {
                            key: 'landing',
                            data: data
                        }
    
                        commit('SET_CONFIG', payload)

                        // localStorage.setItem(key, JSON.stringify(state.config))
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    
                Api.get(`config/payment`, params)
                    .then((res) => {
                        var data = res.data.data
                        
                        var payload = {
                            key: 'payment',
                            data: data
                        }
    
                        commit('SET_CONFIG', payload)

                        // localStorage.setItem(key, JSON.stringify(state.config))
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    
                Api.get(`config/socmed`, params)
                    .then((res) => {
                        var data = res.data.data
                        
                        var payload = {
                            key: 'socmed',
                            data: data
                        }
    
                        commit('SET_CONFIG', payload)

                        // localStorage.setItem(key, JSON.stringify(state.config))
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                
                Api.get(`config/affiliation`, params)
                    .then((res) => {
                        var data = res.data.data
                        
                        var payload = {
                            key: 'affiliation',
                            data: data
                        }
    
                        commit('SET_CONFIG', payload)

                        // localStorage.setItem(key, JSON.stringify(state.config))
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            // }else{
            //     var payload = storage

            //     commit('ALL_CONFIG', payload)
            // }
            
            Api.get(`categories/product/main`, params)
                .then((res) => {
                    var data = res.data.data
                    
                    var payload = {
                        key: 'categories',
                        data: data
                    }

                    commit('SET_CONFIG', payload)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getResellerConfig({commit, state}) {
            // const d = new Date();

            // var current_date = d.getFullYear() + d.getMonth() + d.getDate()

            // var key = state.domain + '-config-' + current_date

            Api.get(`reseller/config/store`, {
                    headers: {
                        'X-DOMAIN-MEMBER': state.domain
                    }
                })
                .then((res) => {
                    var data = res.data.data
                    
                    var payload = {
                        key: 'reseller',
                        data: data
                    }

                    commit('SET_CONFIG', payload)

                    // localStorage.setItem(key, JSON.stringify(state.config))
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // clearConfig({state}) {
        //     const d = new Date();

        //     var current_date = d.getFullYear() + d.getMonth() + d.getDate()

        //     var key = state.domain + '-config-' + current_date

        //     localStorage.removeItem(key)
        // }
    },

    getters: {

        getAllConfig(state) {
            return state.config
        },
    }
}

export default config
