//import global API
import Api from '../../api/Api'

import config from '../../store/module/config.js'

const auth = {

    //set namespace true
    namespaced: true,

    //state
    state: {

        //state untuk token, pakai localStorage, untuk menyimpan informasi tentang token JWT
        token: localStorage.getItem('token') || '',

        //state user, pakai localStorage, untuk menyimpan data user yang sedang login
        user: JSON.parse(localStorage.getItem('user')) || {},

        //
        domain: config.state.domain,
        // domain: 'kumi.id',

        member: JSON.parse(localStorage.getItem('member')) || {}

    },

    //mutations
    mutations: {

        //update state token dan state user dari hasil response
        AUTH_SUCCESS(state, token, user) {
            state.token = token // <-- assign state token dengan response token
            state.user = user // <-- assign state user dengan response data user
        },

        //update state user dari hasil response register / login
        GET_USER(state, user) {
            state.user = user // <-- assign state user dengan response data user
        },

        //fungsi logout
        AUTH_LOGOUT(state) {
            state.token = '' // <-- set state token ke empty
            state.user = {} // <-- set state user ke empty array
        },

        UPDATE_PROFILE(state, user) {
            state.user.name = user.name
            state.user.avatar = user.avatar
        },

        UPDATE_MEMBER(state, member) {
            state.member = member
        },

        LOGIN_AS_KUMI(state, is_kumi) {
            state.user.is_kumi = is_kumi
        }

    },

    //actions
    actions: {

        //action register
        register({getters}, user) {
            var url = ''

            if(user.register_as == 'customer') {

                url = '/customer/register'

            }else if(user.register_as == 'reseller') {
                
                url = '/reseller/register'

            }else{  

                url = '/admin/register'
                
            }

            //define callback promise
            return new Promise((resolve, reject) => {

                var domain = getters.currentDomain

                //send data ke server
                Api.post(url, {

                        //data yang dikirim ke serve untuk proses register
                        name: user.name,
                        email: user.email,
                        phone_number: user.phone_number,
                        password: user.password,
                        password_confirmation: user.password_confirmation

                    }, {
                        headers: {
                            'X-DOMAIN-MEMBER': domain,
                        }
                    })

                    .then(response => {

                        //resolve ke component dengan hasil response
                        resolve(response)

                    }).catch(error => {

                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        //action getUserProfile
        getUserProfile({commit, getters}) {

            const domain = getters.currentDomain
            const token = localStorage.getItem('token')

            //ambil data token dari localStorage
            // const token = localStorage.getItem('token')

            // Api.defaults.headers.common['Authorization'] = "Bearer " + token
                
            Api.get('/profile', {
                headers: {
                    'X-DOMAIN-MEMBER': domain,
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                var data = res.data.data
                
                commit('UPDATE_PROFILE', data)
            })
                
            Api.get('/member/customer/check', {
                headers: {
                    'X-DOMAIN-MEMBER': domain,
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                var data = res.data.data

                localStorage.setItem('member', JSON.stringify(data))
                
                commit('UPDATE_MEMBER', data)
            }).catch((err) => {
                console.log(err)
            })

        },

        //action logout
        logout({commit}) {

            //define callback promise
            return new Promise((resolve) => {

                //commit ke mutation AUTH_LOGOUT
                commit('AUTH_LOGOUT')

                //remove value dari localStorage
                // localStorage.removeItem('token')
                // localStorage.removeItem('user')
                // localStorage.removeItem('member')
                // localStorage.removeItem('hello')
                localStorage.clear()

                const d = new Date();
                var current_date = d.getFullYear() + d.getMonth() + d.getDate()
                var key = 'kumi.id-config-' + current_date

                localStorage.removeItem(key)

                /**
                 * commit ke module cart, untuk set mutation dan state cart menjadi kosong
                 */
                commit('cart/CLEAR_CART', 0, {
                    root: true
                })

                commit('notification/CLEAR_NOTIFICATION', 0, {
                    root: true
                })

                // dispatch('config/clearConfig')

                //delete header axios
                // delete Api.defaults.headers.common['Authorization']
                // delete Api.defaults.headers.common['X-DOMAIN-MEMBER']

                //return resolve ke component 
                resolve()

            })
        },

        //action login
        login({commit, getters, dispatch}, user) {

            var domain = getters.currentDomain
            var url = ''

            if(user.login_as == 'customer') {

                url = '/customer/login'

            }else if(user.login_as == 'reseller') {
                
                url = '/reseller/login'

            }else{  

                url = '/admin/login'
                
            }

            //define callback promise
            return new Promise((resolve, reject) => {

                Api.post(url, {

                        //data yang dikirim ke server
                        email: user.email,
                        password: user.password,

                    }, {
                        headers: {
                            'X-DOMAIN-MEMBER': domain,
                        }
                    })

                    .then(response => {

                        //define variable dengan isi hasil response dari server
                        const token = response.data.data.token
                        var data = response.data.data.user

                        data.domain = domain
                        data.login_as = user.login_as

                        //set localStorage untuk menyimpan token dan data user
                
                        Api.get('/is-kumi', {
                            headers: {
                                'X-DOMAIN-MEMBER': domain,
                                'Authorization': `Bearer ${token}`
                            }
                        })
                        .then((res) => {
                            var response_data = res.data.data

                            data.is_kumi = response_data.is_kumi

                            localStorage.setItem('token', token)
                            localStorage.setItem('user', JSON.stringify(data))

                            //commit auth success ke mutation
                            commit('AUTH_SUCCESS', token, user)
    
                            //commit get user ke mutation
                            commit('GET_USER', data)
                            
                            
                            commit('LOGIN_AS_KUMI', response_data.is_kumi)
                        

                            if(user.login_as == 'admin') {
                                return resolve(response)
                            }
    
                            //refresh cart dari module cart
                            dispatch(
                                'cart/refreshCart', 
                                {
    
                                },
                                {
                                    root: true
                                }
                            )
    
                            dispatch('notification/refreshNotification', {}, {
                                root: true
                            })
    
                            dispatch('getUserProfile')
    
                            //resolve ke component dengan hasil response
                            return resolve(response)
                        })

                    }).catch(error => {

                        //jika gagal, remove localStorage dengan key token
                        localStorage.removeItem('token')

                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })

        },

        callbackLogin({commit, getters, dispatch}, payload) {
            
            var domain = getters.currentDomain
            var token = payload.data.token
            var data = payload.data.user
            
            return new Promise((resolve) => {

                data.domain = domain
                data.login_as = payload.login_as

                //set localStorage untuk menyimpan token dan data user
                localStorage.setItem('token', token)
                localStorage.setItem('user', JSON.stringify(data))

                //commit auth success ke mutation
                commit('AUTH_SUCCESS', payload.token, payload.data)

                //commit get user ke mutation
                commit('GET_USER', data)

                if(payload.login_as == 'admin') {
                    return resolve(payload)
                }

                //refresh cart dari module cart
                dispatch(
                    'cart/refreshCart', 
                    {

                    },
                    {
                        root: true
                    }
                )

                dispatch('notification/refreshNotification', {}, {
                    root: true
                })

                resolve(payload)

            })
        },

        loginFacebook({getters}) {

            var domain = getters.currentDomain

            return new Promise((resolve, reject) => {
                Api.get('/customer/facebook/login',  {
                    headers: {
                        'X-DOMAIN-MEMBER': domain,
                        'Access-Control-Allow-Origin': '*'
                    }
                })

                    .then(response => {
                        console.log(response)

                        // document.write(response.data)
                        
                        var popup = window.open("","wildebeast","scrollbars=1,resizable=1")

                        var html = response.data

                        popup.document.open()
                        popup.document.write(html)
                        popup.document.close()

                        resolve(response)

                    }).catch(error => {

                        console.log(error)

                        //jika gagal, remove localStorage dengan key token
                        localStorage.removeItem('token')

                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })
            })
        }

    },

    //getters
    getters: {

        //get current user
        currentUser(state) {
            return state.user // <-- return dengan data user
        },

        //get current member
        currentMember(state) {
            return state.member // <-- return dengan data member
        },
        
        //get current user
        currentDomain(state) {
            return state.domain // <-- return dengan data domain
        },

        //loggedIn
        isLoggedIn(state) {
            return state.token // return dengan data token
        },

    }

}

export default auth