<template>
    <div>
        <div class="card shadow-sm mb-4">
            <div class="card-header bg-white border-0 py-3">
                <div class="d-flex justify-content-start align-items-center">
                    <div class="user-image-initial d-flex align-items-center justify-content-center"
                        :style="{'background-image': 'url(' + user.avatar + ')'}">
                        <h4 v-if="!user.avatar">
                            {{ user.name ? user.name.charAt(0) : '' }}
                        </h4>
                    </div>
                    <div class="px-3">
                        <h5 class="mb-0">
                            {{ user.name }}
                        </h5>
                        <p class="mb-0">
                            {{ user.email }}
                        </p>
                    </div>
                    <div class="ms-auto d-block d-md-none">
                        <a href="#" class="extend-sidemenu" @click="Sidebar = !Sidebar">
                            <i class="fa fa-times" v-if="Sidebar"></i>
                            <i class="fa fa-bars" v-else></i>
                        </a>
                    </div>
                </div>
            </div>
            <div :class="{'card-body sidebar-account p-4': true, 'show': Sidebar}">
                <router-link :to="{name: 'admin-dashboard'}" class="d-flex align-items-center sidebar-text">
                    <img :src="EditProfile" class="show" />
                    <img :src="EditProfileActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Dashboard</h6>
                </router-link>
                
                <a href="#" class="d-flex align-items-center sidebar-text" @click="toggleMenu('order')">
                    <img :src="Transaction" class="show" />
                    <img :src="TransactionActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Orders</h6>
                    <i :class="{'fa fa-angle-up': menus.order, 'fa fa-angle-down': !menus.order}"></i>
                </a>
                <div :class="{'child-menu': true, 'show': menus.order}">
                    <router-link :to="{name: 'admin-orders'}" class="d-flex align-items-center sidebar-text">
                        <h6 class="px-3 mb-0">Newest</h6>
                    </router-link>
                    <router-link :to="{name: 'admin-order-progress'}" class="d-flex align-items-center sidebar-text">
                        <h6 class="px-3 mb-0">On Progress</h6>
                    </router-link>
                    <router-link :to="{name: 'admin-order-delivery'}" class="d-flex align-items-center sidebar-text">
                        <h6 class="px-3 mb-0">On Delivery</h6>
                    </router-link>
                    <router-link :to="{name: 'admin-order-delivered'}" class="d-flex align-items-center sidebar-text">
                        <h6 class="px-3 mb-0">Delivered</h6>
                    </router-link>
                    <router-link :to="{name: 'admin-order-done'}" class="d-flex align-items-center sidebar-text">
                        <h6 class="px-3 mb-0">Done</h6>
                    </router-link>
                    <router-link :to="{name: 'admin-order-canceled'}" class="d-flex align-items-center sidebar-text">
                        <h6 class="px-3 mb-0">Cancel</h6>
                    </router-link>
                    <router-link :to="{name: 'admin-order-complaint'}" class="d-flex align-items-center sidebar-text">
                        <h6 class="px-3 mb-0">Complaint</h6>
                    </router-link>
                    <router-link :to="{name: 'admin-order-refund'}" class="d-flex align-items-center sidebar-text">
                        <h6 class="px-3 mb-0">Refund</h6>
                    </router-link>
                </div>
                
                <a href="#" class="d-flex align-items-center sidebar-text" @click="toggleMenu('product')">
                    <img :src="Product" class="show" />
                    <img :src="ProductActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Products</h6>
                    <i :class="{'fa fa-angle-up': menus.product, 'fa fa-angle-down': !menus.product}"></i>
                </a>
                <div :class="{'child-menu': true, 'show': menus.product}">
                    <router-link :to="{name: 'admin-products'}" class="d-flex align-items-center sidebar-text">
                        <h6 class="px-3 mb-0">Product List</h6>
                    </router-link>
                    <router-link :to="{name: 'admin-products-categories'}" class="d-flex align-items-center sidebar-text">
                        <h6 class="px-3 mb-0">Category</h6>
                    </router-link>
                </div>

                <router-link :to="{name: 'admin-package'}" class="d-flex align-items-center sidebar-text" v-if="user.is_kumi">
                    <img :src="Product" class="show" />
                    <img :src="ProductActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Package</h6>
                </router-link>

                <a href="#" class="d-flex align-items-center sidebar-text" @click="toggleMenu('wallet')">
                    <img :src="Money" class="show" />
                    <img :src="MoneyActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Wallets</h6>
                    <i :class="{'fa fa-angle-up': menus.wallet, 'fa fa-angle-down': !menus.wallet}"></i>
                </a>
                <div :class="{'child-menu': true, 'show': menus.wallet}">
                    <router-link :to="{name: 'admin-wallet'}" class="d-flex align-items-center sidebar-text">
                        <h6 class="px-3 mb-0">Wallet</h6>
                    </router-link>
                    <router-link :to="{name: 'admin-wallet-mutasi-onprocess'}" class="d-flex align-items-center sidebar-text">
                        <h6 class="px-3 mb-0">Withdraw On Process</h6>
                    </router-link>
                </div>

                <router-link :to="{name: 'admin-coupons'}" class="d-flex align-items-center sidebar-text">
                    <img :src="Wishlist" class="show" />
                    <img :src="WishlistActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Coupons</h6>
                </router-link>
                <router-link :to="{name: 'admin-customers'}" class="d-flex align-items-center sidebar-text">
                    <img :src="EditProfile" class="show" />
                    <img :src="EditProfileActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Customers</h6>
                </router-link>
                <router-link :to="{name: 'admin-resellers'}" class="d-flex align-items-center sidebar-text">
                    <img :src="Store" class="show" />
                    <img :src="StoreActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Resellers</h6>
                </router-link>

                <a href="#" class="d-flex align-items-center sidebar-text" @click="toggleMenu('article')">
                    <img :src="Marketing" class="show" />
                    <img :src="MarketingActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Article</h6>
                    <i :class="{'fa fa-angle-up': menus.article, 'fa fa-angle-down': !menus.article}"></i>
                </a>
                <div :class="{'child-menu': true, 'show': menus.article}">
                    <router-link :to="{name: 'admin-blogs'}" class="d-flex align-items-center sidebar-text">
                        <h6 class="px-3 mb-0">Article List</h6>
                    </router-link>
                    <router-link :to="{name: 'admin-blog-categories'}" class="d-flex align-items-center sidebar-text">
                        <h6 class="px-3 mb-0">Category</h6>
                    </router-link>
                </div>

                <router-link :to="{name: 'admin-faq-config'}" class="d-flex align-items-center sidebar-text">
                    <img :src="Discussion" class="show" />
                    <img :src="DiscussionActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">FAQ</h6>
                </router-link>
                <router-link :to="{name: 'admin-theme-config'}" class="d-flex align-items-center sidebar-text">
                    <img :src="Setting" class="show" />
                    <img :src="SettingActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Configuration</h6>
                </router-link>
                <router-link :to="{name: 'admin-change-password'}" class="d-flex align-items-center sidebar-text">
                    <img :src="PasswordSettings" class="show" />
                    <img :src="PasswordSettingsActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Change Password</h6>
                </router-link>
                <!-- <router-link :to="{name: 'admin-edit-profile'}" class="d-flex align-items-center sidebar-text">
                    <img :src="EditProfile" class="show" />
                    <img :src="EditProfileActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Edit Profile</h6>
                </router-link> -->
                <router-link :to="{name: 'admin-forum'}" class="d-flex align-items-center sidebar-text">
                    <img :src="Discussion" class="show" />
                    <img :src="DiscussionActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Forum</h6>
                </router-link>
                <router-link :to="{name: 'admin-members'}" class="d-flex align-items-center sidebar-text" v-if="user.is_kumi">
                    <img :src="Member" class="show" />
                    <img :src="MemberActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Membership</h6>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>    

    import EditProfile from '../assets/icons/edit-profile.svg';
    import AddressSettings from '../assets/icons/address-settings.svg';
    import PasswordSettings from '../assets/icons/password-settings.svg';
    import Wishlist from '../assets/icons/wishlist.svg';
    import Transaction from '../assets/icons/transaction.svg';
    import Money from '../assets/icons/money.svg';
    import Sales from '../assets/icons/sales.svg';
    import Marketing from '../assets/icons/marketing.svg';
    import Product from '../assets/icons/product.svg';
    import Discussion from '../assets/icons/discussion.svg';
    import Setting from '../assets/icons/setting.svg';
    import Guide from '../assets/icons/guide.svg';
    import Store from '../assets/icons/store.svg';
    import Member from '../assets/icons/member.svg';

    import EditProfileActive from '../assets/icons/edit-profile-active.svg';
    import AddressSettingsActive from '../assets/icons/address-settings-active.svg';
    import PasswordSettingsActive from '../assets/icons/password-settings-active.svg';
    import WishlistActive from '../assets/icons/wishlist-active.svg';
    import TransactionActive from '../assets/icons/transaction-active.svg';
    import MoneyActive from '../assets/icons/money-blue.svg';
    import SalesActive from '../assets/icons/sales-blue.svg';
    import MarketingActive from '../assets/icons/marketing-blue.svg';
    import ProductActive from '../assets/icons/product-blue.svg';
    import DiscussionActive from '../assets/icons/discussion-blue.svg';
    import SettingActive from '../assets/icons/setting-blue.svg';
    import GuideActive from '../assets/icons/guide-blue.svg';
    import StoreActive from '../assets/icons/store-blue.svg';
    import MemberActive from '../assets/icons/member-blue.svg';

    export default {
        setup() {

            return {
                EditProfile,
                AddressSettings,
                PasswordSettings,
                Wishlist,
                Transaction,
                Money,
                Sales,
                Marketing,
                Product,
                Discussion,
                Setting,
                Guide,
                Store,
                Member,

                EditProfileActive,
                AddressSettingsActive,
                PasswordSettingsActive,
                WishlistActive,
                TransactionActive,
                MoneyActive,
                SalesActive,
                MarketingActive,
                ProductActive,
                DiscussionActive,
                SettingActive,
                GuideActive,
                StoreActive,
                MemberActive,
            }
        },
        data() {
            return {
                Sidebar: false,
                menus: {
                    article: false,
                    product: false,
                    order: false,
                    wallet: false,
                }
            }
        },
        computed: {
            user() {
                return this.$store.getters['auth/currentUser']
            }
        },
        methods: {
            toggleMenu(menu) {
                this.menus[menu] = !this.menus[menu]
            }
        }
    }
</script>

<style scoped>
    .child-menu {
        border-left: solid 2px var(--blue);
        padding-left: .75rem;
        /* margin-left: calc(-1rem - 2px); */
        margin-bottom: 1rem;
        display: none;
    }

    .child-menu.show {
        display: block;
    }
    
    .user-image-initial {
        background-color: #FFFFFF;
        border: 1px solid var(--blue);
        text-align: center;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        flex: 50px 0 0;
        background-size: cover;
        background-position: center;
    }

    .user-image-initial h4 {
        font-weight: bold;
        font-size: 19px;
        line-height: 26px;
        color: var(--blue);
        margin-bottom: 0;
    }

    .sidebar-account {
        height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .sidebar-text {
        text-decoration: none;
        margin-bottom: 20px;
    }

    .show {
        transition: 0.5s;
    }

    .mouse-hover {
        display: none;
    }

    .sidebar-text h6 {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #404040;
        text-decoration: none;
        transition: 0.5s;
    }

    .sidebar-text img {
        width: 15px;
    }

    .sidebar-text:hover h6 {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: var(--blue);
        text-decoration: none;
    }

    .sidebar-text:hover .show {
        display: none;
    }

    .sidebar-text:hover .mouse-hover {
        display: block;
    }

    .card-separator {
        height: 2px;
        width: calc(100% + 3rem);
        background: var(--grey);
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: -1.5rem;
    }
</style>