<template>
  <div>
    <Header/>
    <div class="reseller-page bg-light">
      <router-view/>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import Header from '../components/HeaderReseller.vue'
  import Footer from '../components/FooterReseller.vue'

  export default {
    components : {
      Header,
      Footer
    },
  }
</script>
<style lang="scss" scoped>
  .reseller-page{
    // padding-top: 30px;
  }
  .reseller-content{
    display: flex;
    .left-side{
      width: 280px;
      height: auto;
    }
    .right-side{
      width: calc(100% - 280px);
      padding-left: 1.5rem;
    }
  }
</style>