import Api from '../../api/Api'

const notification = {

    namespaced: true,

    state: {
        notification: [],

        totalUnread: 0
    },

    mutations: {
        GET_NOTIFICATION(state, data) {
            state.notification = data['notifications']
            state.totalUnread = data['unread']
        },

        CLEAR_NOTIFICATION(state) {
            state.notification = []
            state.totalUnread = 0
        }
    },

    actions: {
        refreshNotification({ commit }) {
            const token = localStorage.getItem('token')
            const user  = JSON.parse(localStorage.getItem('user'))

            if(!user) {
                return false
            }

            Api.get('/notifications', {
                headers: {
                    'X-DOMAIN-MEMBER': user.domain,
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                
                //commit mutation GET_NOTIFICATION
                commit('GET_NOTIFICATION', response.data.data)

            })
        },
        markAsRead({ dispatch }, payload) {
            
            //get data token dan user
            const token = localStorage.getItem('token')
            const user  = JSON.parse(localStorage.getItem('user'))
            
            return new Promise((resolve, reject) => {

                var data = null
                if (payload != null) {
                    data  = {
                        id : payload
                    }
                }
                //send data 
                Api.patch(`/notifications/read`, data, {
                    headers: {
                        'X-DOMAIN-MEMBER': user.domain,
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {

                    //get
                    dispatch('refreshNotification')

                    resolve(response)

                })
                .catch(error => {
                    console.log(error)

                    //reject ke component dengan hasil response
                    reject(error.response.data)

                })
            })
        },
        clearNotification({ dispatch }, payload) {
            
            //get data token dan user
            const token = localStorage.getItem('token')
            const user = JSON.parse(localStorage.getItem('user'))
            
            return new Promise((resolve, reject) => {

                var url = payload == null ? '/notifications/delete' : `/notifications/delete/${payload}`
                //send data 
                Api.delete(url, {
                    headers: {
                        'X-DOMAIN-MEMBER': user.domain,
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {

                    //get
                    dispatch('refreshNotification')
                
                    resolve(response)

                })
                .catch(error => {
                    console.log(error)

                    //reject ke component dengan hasil response
                    reject(error.response.data)

                })
            })
        },
    },

    getters: {

        getNotifications(state) {
            return state
        }
    }
}

export default notification