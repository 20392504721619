<template>
    <div class="card shadow-sm mb-4">
        <div class="card-header bg-white border-0 py-3">
            <div class="d-flex justify-content-start align-items-center">
                <div class="user-image-initial d-flex align-items-center justify-content-center"
                    :style="{'background-image': 'url(' + user.avatar + ')'}">
                    <h4 v-if="!user.avatar">
                        {{ user.name ? user.name.charAt(0) : '' }}
                    </h4>
                </div>
                <div class="px-3">
                    <h5 class="mb-0">
                        {{ user.name }}
                    </h5>
                    <p class="mb-0">
                        {{ user.email }}
                    </p>
                </div>
                <div class="ms-auto d-block d-md-none">
                    <a href="#" class="extend-sidemenu" @click="Sidebar = !Sidebar">
                        <i class="fas fa-times" v-if="Sidebar"></i>
                        <i class="fas fa-bars" v-else></i>
                    </a>
                </div>
            </div>
        </div>
        <div :class="{'card-body sidebar-account p-4': true, 'show': Sidebar}">
            <router-link :to="{name: 'reseller-edit-profile'}" class="d-flex align-items-center sidebar-text">
                <img :src="EditProfile" class="show" />
                <img :src="EditProfileActive" class="mouse-hover" />
                <h6 class="px-3 mb-0">Edit Profile</h6>
            </router-link>
            <router-link :to="{name: 'reseller-settings-address'}" class="d-flex align-items-center sidebar-text">
                <img :src="AddressSettings" class="show" />
                <img :src="AddressSettingsActive" class="mouse-hover" />
                <h6 class="px-3 mb-0">Address Setting</h6>
            </router-link>
            <router-link :to="{name: 'reseller-settings-password'}" class="d-flex align-items-center sidebar-text">
                <img :src="PasswordSettings" class="show" />
                <img :src="PasswordSettingsActive" class="mouse-hover" />
                <h6 class="px-3 mb-0">Password Setting</h6>
            </router-link>
            <router-link :to="{name: 'reseller-wishlist'}" class="d-flex align-items-center sidebar-text">
                <img :src="Wishlist" class="show" />
                <img :src="WishlistActive" class="mouse-hover" />
                <h6 class="px-3 mb-0">Wishlist</h6>
            </router-link>
            <router-link :to="{name: 'reseller-order-pay'}" class="d-flex align-items-center sidebar-text">
                <img :src="Transaction" class="show" />
                <img :src="TransactionActive" class="mouse-hover" />
                <h6 class="px-3 mb-0">Transaction</h6>
            </router-link>

            <div class="card-separator"></div>

            <router-link :to="{name: 'reseller-products'}" class="d-flex align-items-center sidebar-text">
                <img :src="Product" class="show" />
                <img :src="ProductActive" class="mouse-hover" />
                <h6 class="px-3 mb-0">Product</h6>
            </router-link>
            <a href="#" class="d-flex align-items-center sidebar-text" @click="showAffiliateChildMenu()">
                <img :src="Store" class="show" />
                <img :src="StoreActive" class="mouse-hover" />
                <h6 class="px-3 mb-0">Affiliate</h6>
                <i
                    :class="{'fa fa-angle-up': affiliate.child_menu_toggle, 'fa fa-angle-down': !affiliate.child_menu_toggle}"></i>
            </a>
            <div :class="{'affiliate-menu': true, 'show': affiliate.child_menu_toggle}">
                <router-link :to="{name: 'reseller-wallet'}" class="d-flex align-items-center sidebar-text">
                    <img :src="Money" class="show" />
                    <img :src="MoneyActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Affiliater Wallet</h6>
                </router-link>
                <router-link :to="{name: 'reseller-sales-pay'}" class="d-flex align-items-center sidebar-text">
                    <img :src="Sales" class="show" />
                    <img :src="SalesActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">History Sales</h6>
                </router-link>
                <router-link :to="{name: 'reseller-bank'}" class="d-flex align-items-center sidebar-text">
                    <img :src="Money" class="show" />
                    <img :src="MoneyActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Withdrawal Account</h6>
                </router-link>
                <router-link :to="{name: 'reseller-guide'}" class="d-flex align-items-center sidebar-text">
                    <img :src="Guide" class="show" />
                    <img :src="GuideActive" class="mouse-hover" />
                    <h6 class="px-3 mb-0">Guide</h6>
                </router-link>
            </div>

            <router-link :to="{name: 'reseller-marketing'}" class="d-flex align-items-center sidebar-text">
                <img :src="Marketing" class="show" />
                <img :src="MarketingActive" class="mouse-hover" />
                <h6 class="px-3 mb-0">Marketing Kit</h6>
            </router-link>
            <router-link :to="{name: 'reseller-forum'}" class="d-flex align-items-center sidebar-text">
                <img :src="Discussion" class="show" />
                <img :src="DiscussionActive" class="mouse-hover" />
                <h6 class="px-3 mb-0">Forums</h6>
            </router-link>
            <router-link :to="{name: 'reseller-store-config'}" class="d-flex align-items-center sidebar-text">
                <img :src="Setting" class="show" />
                <img :src="SettingActive" class="mouse-hover" />
                <h6 class="px-3 mb-0">Store Setting</h6>
            </router-link>
            <router-link :to="{name: 'reseller-membership'}" class="d-flex align-items-center sidebar-text" v-if="user.is_kumi">
                <img :src="Member" class="show" width="18" />
                <img :src="MemberActive" class="mouse-hover" width="18" />
                <h6 class="px-3 mb-0">Membership</h6>
            </router-link>
        </div>
    </div>
</template>

<script>
    import {
        reactive
    } from 'vue'

    import EditProfile from '../assets/icons/edit-profile.svg';
    import AddressSettings from '../assets/icons/address-settings.svg';
    import PasswordSettings from '../assets/icons/password-settings.svg';
    import Wishlist from '../assets/icons/wishlist.svg';
    import Transaction from '../assets/icons/transaction.svg';
    import Money from '../assets/icons/money.svg';
    import Sales from '../assets/icons/sales.svg';
    import Marketing from '../assets/icons/marketing.svg';
    import Product from '../assets/icons/product.svg';
    import Discussion from '../assets/icons/discussion.svg';
    import Setting from '../assets/icons/setting.svg';
    import Guide from '../assets/icons/guide.svg';
    import Store from '../assets/icons/store.svg';
    import Member from '../assets/icons/member.svg';

    import EditProfileActive from '../assets/icons/edit-profile-active.svg';
    import AddressSettingsActive from '../assets/icons/address-settings-active.svg';
    import PasswordSettingsActive from '../assets/icons/password-settings-active.svg';
    import WishlistActive from '../assets/icons/wishlist-active.svg';
    import TransactionActive from '../assets/icons/transaction-active.svg';
    import MoneyActive from '../assets/icons/money-blue.svg';
    import SalesActive from '../assets/icons/sales-blue.svg';
    import MarketingActive from '../assets/icons/marketing-blue.svg';
    import ProductActive from '../assets/icons/product-blue.svg';
    import DiscussionActive from '../assets/icons/discussion-blue.svg';
    import SettingActive from '../assets/icons/setting-blue.svg';
    import GuideActive from '../assets/icons/guide-blue.svg';
    import StoreActive from '../assets/icons/store-blue.svg';
    import MemberActive from '../assets/icons/member-blue.svg';

    export default {
        setup() {
            const affiliate = reactive({
                child_menu_toggle: false
            })

            function showAffiliateChildMenu() {
                affiliate.child_menu_toggle = !affiliate.child_menu_toggle
            }

            return {
                EditProfile,
                AddressSettings,
                PasswordSettings,
                Wishlist,
                Transaction,
                Money,
                Sales,
                Marketing,
                Product,
                Discussion,
                Setting,
                Guide,
                Store,
                Member,

                EditProfileActive,
                AddressSettingsActive,
                PasswordSettingsActive,
                WishlistActive,
                TransactionActive,
                MoneyActive,
                SalesActive,
                MarketingActive,
                ProductActive,
                DiscussionActive,
                SettingActive,
                GuideActive,
                StoreActive,

                affiliate,
                showAffiliateChildMenu,
                MemberActive,
            }
        },
        data() {
            return {
                Sidebar: false
            }
        },
        computed: {
            user() {
                return this.$store.getters['auth/currentUser']
            }
        },
    }
</script>

<style scoped>
    .affiliate-menu {
        border-left: solid 2px var(--blue);
        padding-left: 1rem;
        margin-left: calc(-1rem - 2px);
        margin-bottom: 1rem;
        display: none;
    }

    .affiliate-menu.show {
        display: block
    }
    
    .user-image-initial {
        background-color: #FFFFFF;
        border: 1px solid var(--blue);
        text-align: center;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        flex: 50px 0 0;
        background-size: cover;
        background-position: center;
    }

    .user-image-initial h4 {
        font-weight: bold;
        font-size: 19px;
        line-height: 26px;
        color: var(--blue);
        margin-bottom: 0;
    }

    .sidebar-account {
        height: 70vh;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .sidebar-text {
        text-decoration: none;
        margin-bottom: 20px;
    }

    .show {
        transition: 0.5s;
    }

    .mouse-hover {
        display: none;
    }

    .sidebar-text h6 {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #404040;
        text-decoration: none;
        transition: 0.5s;
    }

    .sidebar-text img {
        width: 15px;
    }

    .sidebar-text:hover h6 {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: var(--blue);
        text-decoration: none;
    }

    .sidebar-text:hover .show {
        display: none;
    }

    .sidebar-text:hover .mouse-hover {
        display: block;
    }

    .card-separator {
        height: 2px;
        width: calc(100% + 3rem);
        background: var(--grey);
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        margin-left: -1.5rem;
    }
</style>