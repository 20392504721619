//import vue router
import {
    createRouter,
    createWebHistory
} from 'vue-router'

//import store vuex
import store from '@/store'


//define a routes
const routes = [
    //main pages
    {
        path: '/register',
        name: 'register',
        meta: {
            layout: 'customer',
            requiresAuth: false
        },
        component: () => import('@/views/customer/auth/Register.vue'),
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            layout: 'customer',
            requiresAuth: false
        },
        component: () => import('@/views/customer/auth/Login.vue'),
        props: true,
    },
    {
        path: '/login/callback',
        name: 'login-callback',
        meta: {
            layout: 'customer',
            requiresAuth: false
        },
        component: () => import('@/views/customer/auth/CallbackLogin.vue'),
        props: true,
    },
    {
        path: '/forgot',
        name: 'forgot',
        meta: {
            layout: 'customer',
            requiresAuth: false
        },
        component: () => import('@/views/customer/auth/ForgotPassword.vue')
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta: {
            layout: 'customer',
            requiresAuth: false
        },
        component: () => import('@/views/customer/auth/ResetPassword.vue')
    },
    {
        path: '/email/verify/:id/:hash',
        name: 'verify',
        meta: {
            layout: 'customer',
            requiresAuth: true,
            isCustomer: true,
            isReseller: true
        },
        component: () => import('@/views/auth/VerifyEmail.vue'),
        props: true,
    },
    {
        path: '/',
        name: 'home',
        meta: {
            layout: 'customer',
            requiresAuth: false
        },
        component: () => import('../views/store/Index.vue')
    },
    {
        path: '/product/:slug',
        name: 'product',
        meta: {
            layout: 'customer',
            requiresAuth: false
        },
        component: () => import('../views/store/product/Show.vue'),
        props: true,
    },
    {
        path: '/products',
        name: 'categories',
        meta: {
            layout: 'customer',
            requiresAuth: false
        },
        props: true,
        component: () => import('../views/store/product/Index.vue')
    },
    {
        path: '/cart',
        name: 'cart',
        meta: {
            layout: 'customer',
            requiresAuth: false
        },
        component: () => import('../views/store/Cart.vue'),
    },
    {
        path: '/checkout',
        name: 'checkout',
        meta: {
            layout: 'customer',
            requiresAuth: false
        },
        component: () => import('../views/customer/transaction/Checkout.vue'),
    },
    {
        path: '/payment',
        name: 'payment',
        meta: {
            layout: 'customer',
            requiresAuth: true,
            isCustomer: true
        },
        component: () => import('../views/customer/transaction/Payment.vue'),
    },
    {
        path: '/payment/:slug',
        name: 'payment-choose',
        meta: {
            layout: 'customer',
            requiresAuth: true,
            isCustomer: true
        },
        component: () => import('../views/customer/transaction/PaymentChoose.vue'),
        props: true,
    },
    {
        path: '/order',
        name: 'order',
        meta: {
            layout: 'customer',
            requiresAuth: true,
            isCustomer: true
        },
        component: () => import('../views/customer/transaction/Payment.vue'),
    },
    {
        path: '/about',
        name: 'about',
        meta: {
            layout: 'customer',
        },
        component: () => import('../views/store/About.vue'),
    },
    {
        path: '/contact',
        name: 'contact',
        meta: {
            layout: 'customer',
        },
        component: () => import('../views/store/Contact.vue'),
    },
    {
        path: '/blog',
        name: 'blog',
        meta: {
            layout: 'customer',
        },
        component: () => import('../views/store/blog/Index.vue'),
    },
    {
        path: '/blog/:slug',
        name: 'blog-show',
        meta: {
            layout: 'customer',
        },
        component: () => import('../views/store/blog/Show.vue'),
        props: true,
    },
    {
        path: '/term-and-condition',
        name: 'term-and-condition',
        meta: {
            layout: 'customer',
        },
        component: () => import('../views/store/Term.vue'),
    },
    {
        path: '/faq',
        name: 'faq',
        meta: {
            layout: 'customer',
        },
        component: () => import('../views/store/Faq.vue'),
    },
    //.main pages

    //customer
    {
        path: '/customer',
        component: () => import('../views/customer/Index.vue'),
        children: [
            {
                path: '',
                name: 'dashboard-customer',
                meta: {
                    layout: 'customer',
                    requiresAuth: true
                },
                component: () => import('../views/customer/Dashboard.vue'),
            },
            {
                path: 'edit-profile',
                name: 'edit-profile',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/EditProfile.vue'),
            },
            {
                path: 'membership',
                name: 'membership',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true,
                    isKumi: true
                },
                component: () => import('../views/customer/Membership.vue'),
            },
            {
                path: 'settings-password',
                name: 'settings-password',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/ChangePassword.vue'),
            },
            {
                path: 'wishlist',
                name: 'wishlist',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/Wishlist.vue'),
            },
            {
                path: 'address',
                name: 'settings-address',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/address/Index.vue'),
            },
            {
                path: 'address/create',
                name: 'add-address',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/address/Create.vue'),
            },
            {
                path: 'address/edit/:id',
                name: 'edit-address',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/address/Edit.vue'),
                props: true,
            },
            {
                path: 'order/refund',
                name: 'refund',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/order/Refund.vue'),
            },
            {
                path: 'order/pay',
                name: 'pay',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/order/Pay.vue'),
            },
            {
                path: 'order/process',
                name: 'process',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/order/Process.vue'),
            },
            {
                path: 'order/send',
                name: 'send',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/order/Send.vue'),
            },
            {
                path: 'order/canceled',
                name: 'canceled',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/order/Canceled.vue'),
            },
            {
                path: 'order/delivered',
                name: 'delivered',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/order/Delivered.vue'),
            },
            {
                path: 'order/done',
                name: 'done',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/order/Done.vue'),
            },
            {
                path: 'order/refund',
                name: 'refund',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/order/Refund.vue'),
            },
            {
                path: 'order/complaint',
                name: 'complaint',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/order/Complaint.vue'),
            },
            {
                path: 'wallet',
                component: () => import('../views/customer/wallet/Index.vue'),
                children: [
                    {
                        path: '',
                        name: 'customer-wallet',
                        meta: {
                            layout: 'customer',
                            requiresAuth: true,
                            isCustomer: true
                        },
                        component: () => import('../views/customer/wallet/Withdraw.vue'),
                    },
                    {
                        path: '/mutasi',
                        name: 'customer-wallet-mutasi',
                        meta: {
                            layout: 'customer',
                            requiresAuth: true,
                            isCustomer: true
                        },
                        component: () => import('../views/customer/wallet/History.vue'),
                    },
                    {
                        path: '/on-process',
                        name: 'customer-wallet-mutasi-onprocess',
                        meta: {
                            layout: 'customer',
                            requiresAuth: true,
                            isCustomer: true
                        },
                        component: () => import('../views/customer/wallet/On-Process.vue'),
                    },
                ],
            },
            {
                path: '/bank',
                name: 'customer-bank',
                meta: {
                    layout: 'customer',
                    requiresAuth: true,
                    isCustomer: true
                },
                component: () => import('../views/customer/bank/Index.vue'),
            },
        ]
    },
    //.customer

    //reseller
    {
        path: '/reseller',
        component: () => import('../views/reseller/Index.vue'),
        children: [
            {
                path: '',
                name: 'dashboard-reseller',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                // component: () => import('../views/reseller/Dashboard.vue'),
                component: () => import('../views/reseller/EditProfile.vue')
            },
            {
                path: 'edit-profile',
                name: 'reseller-edit-profile',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/EditProfile.vue')
            },
            {
                path: 'membership',
                name: 'reseller-membership',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/Membership.vue'),
            },
            {
                path: 'login',
                name: 'reseller-login',
                component: () => import('../views/reseller/auth/Login.vue')
            },
            {
                path: 'login/callback',
                name: 'reseller-login-callback',
                component: () => import('../views/reseller/auth/CallbackLogin.vue'),
                props: true,
            },
            {
                path: 'register',
                name: 'reseller-register',
                component: () => import('../views/reseller/auth/Register.vue')
            },
            {
                path: 'settings-password',
                name: 'reseller-settings-password',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/ChangePassword.vue'),
            },
            {
                path: 'wishlist',
                name: 'reseller-wishlist',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/Wishlist.vue'),
            },
            {
                path: 'address/',
                name: 'reseller-settings-address',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/address/Index.vue'),
            },
            {
                path: 'address/create',
                name: 'reseller-add-address',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/address/Create.vue'),
            },
            {
                path: 'address/:id',
                name: 'reseller-edit-address',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/address/Edit.vue'),
                props: true,
            },
            {
                path: 'order/pay',
                name: 'reseller-order-pay',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/order/Pay.vue'),
            },
            {
                path: 'order/process',
                name: 'reseller-order-process',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/order/Process.vue'),
            },
            {
                path: 'order/send',
                name: 'reseller-order-send',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/order/Send.vue'),
            },
            {
                path: 'order/delivered',
                name: 'reseller-order-delivered',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/order/Delivered.vue'),
            },
            {
                path: 'order/done',
                name: 'reseller-order-done',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/order/Done.vue'),
            },
            {
                path: 'order/canceled',
                name: 'reseller-order-canceled',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/order/Canceled.vue'),
            },
            {
                path: 'order/complaint',
                name: 'reseller-order-complaint',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/order/Complaint.vue'),
            },
            {
                path: 'order/refund',
                name: 'reseller-order-refund',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/order/Refund.vue'),
            },
            {
                path: 'bank',
                name: 'reseller-bank',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/affiliate/bank/Index.vue')
            },
            {
                path: 'wallet',
                component: () => import('../views/reseller/affiliate/wallet/Index.vue'),
                children: [
                    {
                        path: '',
                        name: 'reseller-wallet',
                        meta: {
                            layout: 'reseller-panel',
                            requiresAuth: true,
                            isReseller: true
                        },
                        component: () => import('../views/reseller/affiliate/wallet/Withdraw.vue'),
                    },
                    {
                        path: '/mutasi',
                        name: 'reseller-wallet-mutasi',
                        meta: {
                            layout: 'reseller-panel',
                            requiresAuth: true,
                            isReseller: true
                        },
                        component: () => import('../views/reseller/affiliate/wallet/History.vue'),
                    },
                    {
                        path: '/on-process',
                        name: 'reseller-wallet-mutasi-onprocess',
                        meta: {
                            layout: 'reseller-panel',
                            requiresAuth: true,
                            isReseller: true
                        },
                        component: () => import('../views/reseller/affiliate/wallet/On-Process.vue'),
                    },
                ],
            },
            {
                path: 'products',
                name: 'reseller-products',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/product/Index.vue'),
            },
            {
                path: 'product/:slug',
                name: 'reseller-product-show',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/product/Show.vue'),
                props: true,
            },
            {
                path: 'sales/pay',
                name: 'reseller-sales-pay',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/affiliate/sales/Pay.vue'),
            },
            {
                path: 'sales/process',
                name: 'reseller-sales-process',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/affiliate/sales/Process.vue'),
            },
            {
                path: 'sales/send',
                name: 'reseller-sales-send',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/affiliate/sales/Send.vue'),
            },
            {
                path: 'sales/delivered',
                name: 'reseller-sales-delivered',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/affiliate/sales/Delivered.vue'),
            },
            {
                path: 'sales/done',
                name: 'reseller-sales-done',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/affiliate/sales/Done.vue'),
            },
            {
                path: 'sales/canceled',
                name: 'reseller-sales-canceled',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/affiliate/sales/Canceled.vue'),
            },
            {
                path: 'sales/complaint',
                name: 'reseller-sales-complaint',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/affiliate/sales/Complaint.vue'),
            },
            {
                path: 'sales/refund',
                name: 'reseller-sales-refund',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/affiliate/sales/Refund.vue'),
            },
            {
                path: 'guide',
                name: 'reseller-guide',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/affiliate/Guide.vue'),
            },
            {
                path: 'forum/',
                name: 'reseller-forum',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/forum/Index.vue'),
            },
            {
                path: 'forum/create',
                name: 'reseller-add-forum',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/forum/Create.vue'),
            },
            {
                path: 'forum/:slug',
                name: 'reseller-forum-detail',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/forum/Show.vue'),
                props: true,
            },
            {
                path: 'marketing',
                name: 'reseller-marketing',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/Marketing.vue'),
            },
            {
                path: 'store-config',
                name: 'reseller-store-config',
                meta: {
                    layout: 'reseller-panel',
                    requiresAuth: true,
                    isReseller: true
                },
                component: () => import('../views/reseller/StoreConfig.vue'),
            },
            {
                path: 'cart',
                name: 'reseller-cart',
                meta: {
                    layout: 'reseller',
                },
                component: () => import('../views/reseller/transaction/Cart.vue'),
            },
            {
                path: 'checkout',
                name: 'reseller-checkout',
                meta: {
                    layout: 'reseller',
                },
                component: () => import('../views/reseller/transaction/Checkout.vue'),
            },
            {
                path: 'payment',
                name: 'reseller-payment',
                meta: {
                    layout: 'reseller',
                },
                component: () => import('../views/reseller/transaction/Payment.vue'),
            },
            {
                path: 'payment/:slug',
                name: 'reseller-payment-choose',
                meta: {
                    layout: 'reseller',
                },
                component: () => import('../views/reseller/transaction/PaymentChoose.vue'),
                props: true,
            },
            {
                path: 'payment/success',
                name: 'reseller-payment-success',
                meta: {
                    layout: 'reseller',
                },
                component: () => import('../views/reseller/transaction/PaymentSuccess.vue'),
            },
            {
                path: 'forgot',
                name: 'reseller-forgot',
                component: () => import('../views/reseller/auth/ForgotPassword.vue'),
            },
            {
                path: 'reset-password',
                name: 'reseller-reset-password',
                component: () => import('@/views/reseller/auth/ResetPassword.vue')
            },
        ]
    },
    //reseller

    //admin
    {
        path: '/admin',
        component: () => import('../views/admin/Index.vue'),
        children: [
            {
                path: '',
                name: 'admin-dashboard',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/Dashboard.vue')
            },
            {
                path: 'order',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/order/Index.vue'),
                children: [{
                        path: '',
                        name: 'admin-orders',
                        component: () => import('../views/admin/order/Newest.vue')
                    },
                    {
                        path: 'on-progress',
                        name: 'admin-order-progress',
                        component: () => import('../views/admin/order/Progress.vue')
                    },
                    {
                        path: 'on-delivery',
                        name: 'admin-order-delivery',
                        component: () => import('../views/admin/order/Delivery.vue')
                    },
                    {
                        path: 'delivered',
                        name: 'admin-order-delivered',
                        component: () => import('../views/admin/order/Delivered.vue')
                    },
                    {
                        path: 'done',
                        name: 'admin-order-done',
                        component: () => import('../views/admin/order/Done.vue')
                    },
                    {
                        path: 'complaint',
                        name: 'admin-order-complaint',
                        component: () => import('../views/admin/order/Complaint.vue')
                    },
                    {
                        path: 'reviews',
                        name: 'admin-order-reviews',
                        component: () => import('../views/admin/order/Review.vue')
                    },
                    {
                        path: 'canceled',
                        name: 'admin-order-canceled',
                        component: () => import('../views/admin/order/Cancel.vue')
                    },
                    {
                        path: 'refund',
                        name: 'admin-order-refund',
                        component: () => import('../views/admin/order/Refund.vue')
                    },
                ]
            },
            {
                path: 'product/',
                name: 'admin-products',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/product/Index.vue')
            },
            {
                path: 'product/edit/:id',
                name: 'admin-products-edit',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/product/Edit.vue'),
                props: true,
            },
            {
                path: 'product/show/:id',
                name: 'admin-products-show',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/product/Show.vue'),
                props: true,
            },
            {
                path: 'product/add-products',
                name: 'admin-add-products',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/product/Create.vue')
            },
            {
                path: 'package/',
                name: 'admin-package',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true,
                    isKumi: true
                },
                component: () => import('../views/admin/package/Index.vue')
            },
            {
                path: 'package/edit/:id',
                name: 'admin-package-edit',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true,
                    isKumi: true
                },
                component: () => import('../views/admin/package/Edit.vue'),
                props: true,
            },
            {
                path: 'package/show/:id',
                name: 'admin-package-show',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true,
                    isKumi: true
                },
                component: () => import('../views/admin/package/Show.vue'),
                props: true,
            },
            {
                path: 'package/add-package',
                name: 'admin-add-package',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true,
                    isKumi: true
                },
                component: () => import('../views/admin/package/Create.vue')
            },
            {
                path: '/categories',
                name: 'admin-products-categories',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/product/Category.vue')
            },
            {
                path: '/ulasan',
                name: 'admin-products-ulasan',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/product/Reviews.vue')
            },
            {
                path: '/diskusi',
                name: 'admin-products-diskusi',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/product/Discussion.vue')
            },
            {
                path: 'forum/',
                name: 'admin-forum',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/forum/Index.vue'),
            },
            {
                path: 'forum/create',
                name: 'admin-add-forum',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/forum/Create.vue'),
            },
            {
                path: 'forum/:slug',
                name: 'admin-forum-detail',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/forum/Show.vue'),
                props: true,
            },
            {
                path: 'configuration',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/configuration/Index.vue'),
                children: [{
                        path: 'theme',
                        name: 'admin-theme-config',
                        component: () => import('../views/admin/configuration/Theme.vue')
                    },
                    {
                        path: 'affiliate',
                        name: 'admin-affiliate-config',
                        component: () => import('../views/admin/configuration/Affiliate.vue')
                    },
                    {
                        path: 'sliders',
                        name: 'admin-sliders-config',
                        component: () => import('../views/admin/configuration/slider/Index.vue'),
                    },
                    {
                        path: 'sliders/create/:id',
                        name: 'admin-add-slider-config',
                        component: () => import('../views/admin/configuration/slider/Create.vue'),
                        props: true,
                    },
                    {
                        path: 'sliders/edit/:id',
                        name: 'admin-edit-slider-config',
                        component: () => import('../views/admin/configuration/slider/Edit.vue'),
                        props: true,
                    },
                    {
                        path: 'faq',
                        name: 'admin-faq-config',
                        component: () => import('../views/admin/configuration/Faq.vue')
                    },
                    {
                        path: 'payment-methods',
                        name: 'admin-payment-config',
                        component: () => import('../views/admin/configuration/Payment.vue')
                    },
                    {
                        path: 'payment-midtrans-methods',
                        name: 'admin-payment-midtrans-config',
                        component: () => import('../views/admin/configuration/PaymentMidtrans.vue')
                    },
                    {
                        path: 'payment-qris-methods',
                        name: 'admin-payment-qris-config',
                        component: () => import('../views/admin/configuration/PaymentQris.vue')
                    },
                    {
                        path: 'social-media',
                        name: 'admin-socmed-config',
                        component: () => import('../views/admin/configuration/SocialMedia.vue')
                    },
                    {
                        path: 'email',
                        name: 'admin-email-config',
                        component: () => import('../views/admin/configuration/Email.vue')
                    },
                    {
                        path: 'contactus',
                        name: 'admin-contactus-config',
                        component: () => import('../views/admin/configuration/Contactus.vue')
                    },
                    {
                        path: 'google-analytics',
                        name: 'admin-analytics-config',
                        component: () => import('../views/admin/configuration/GoogleAnalytic.vue')
                    },
                    {
                        path: 'landing-page',
                        name: 'admin-landing-config',
                        component: () => import('../views/admin/configuration/LandingPage.vue')
                    },
                    {
                        path: 'website-meta',
                        name: 'admin-meta-config',
                        component: () => import('../views/admin/configuration/Meta.vue')
                    },
                    {
                        path: 'shipping',
                        name: 'admin-shipping-config',
                        component: () => import('../views/admin/configuration/Shipping.vue')
                    },
                    {
                        path: 'platform-fee',
                        name: 'admin-platform-config',
                        component: () => import('../views/admin/configuration/Platform.vue')
                    },
                    {
                        path: 'shipping',
                        name: 'admin-shipping-config',
                        component: () => import('../views/admin/configuration/Shipping.vue')
                    },
                    {
                        path: 'marketing-kit',
                        name: 'admin-marketing-config',
                        component: () => import('../views/admin/configuration/Marketing.vue')
                    },
                    {
                        path: 'about',
                        name: 'admin-about-config',
                        component: () => import('../views/admin/configuration/About.vue')
                    },
                    {
                        path: 'tnc',
                        name: 'admin-tnc-config',
                        component: () => import('../views/admin/configuration/Tnc.vue')
                    }
                ]
            },
            {
                path: 'customers',
                name: 'admin-customers',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/customer/Index.vue')
            },
            {
                path: 'blog/',
                name: 'admin-blogs',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/article/Index.vue')
            },
            {
                path: 'blog/create',
                name: 'admin-blogs-create',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/article/Create.vue')
            },
            {
                path: 'blog/edit/:id',
                name: 'admin-blogs-edit',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/article/Edit.vue'),
                props: true,
            },
            {
                path: 'blog/categories',
                name: 'admin-blog-categories',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/article/category/Index.vue')
            },
            {
                path: 'blog/category/create',
                name: 'admin-blog-category-create',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/article/category/Create.vue')
            },
            {
                path: 'blog/edit-category/:id',
                name: 'edit-blog-categories',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/article/category/Edit.vue'),
                props: true,
            },
            {
                path: 'member/',
                name: 'admin-members',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true,
                    isKumi: true
                },
                component: () => import('../views/admin/member/Index.vue')
            },
            {
                path: 'member/renter',
                name: 'admin-members-renter',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true,
                    isKumi: true
                },
                component: () => import('../views/admin/member/Renter.vue')
            },
            {
                path: 'member/request',
                name: 'admin-members-request',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true,
                    isKumi: true
                },
                component: () => import('../views/admin/member/Request.vue')
            },
            {
                path: 'member/create',
                name: 'admin-members-create',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true,
                    isKumi: true
                },
                component: () => import('../views/admin/member/Create.vue')
            },
            {
                path: 'member/edit/:id',
                name: 'admin-members-edit',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true,
                    isKumi: true
                },
                component: () => import('../views/admin/member/Edit.vue'),
                props: true,
            },
            {
                path: 'coupons',
                name: 'admin-coupons',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/coupon/Index.vue')
            },
            {
                path: 'resellers',
                name: 'admin-resellers',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/reseller/Index.vue')
            },
            {
                path: 'change-password',
                name: 'admin-change-password',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/ChangePassword.vue')
            },
            {
                path: 'wallet',
                name: 'admin-wallet',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/wallet/List.vue'),
            },
            {
                path: '/wallet/on-process',
                name: 'admin-wallet-mutasi-onprocess',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/wallet/On-Process.vue'),
            },
            {
                path: 'edit-profile',
                name: 'admin-edit-profile',
                meta: {
                    layout: 'admin',
                    requiresAuth: true,
                    isAdmin: true
                },
                component: () => import('../views/admin/EditProfile.vue')
            },
            {
                path: 'login',
                name: 'admin-login',
                meta: {
                    layout: ''
                },
                component: () => import('../views/admin/Login.vue')
            },
            {
                path: 'forgot-password',
                name: 'admin-forgot-password',
                meta: {
                    layout: ''
                },
                component: () => import('@/views/admin/auth/ForgotPassword.vue')
            },
            {
                path: 'reset-password',
                name: 'admin-reset-password',
                meta: {
                    layout: ''
                },
                component: () => import('@/views/admin/auth/ResetPassword.vue')
            },

        ]
    },
    //.admin

    {
        path: '/:pathMatch(.*)*',
        component: () => import('../components/404.vue')
    },
]
//create router
const router = createRouter({
    history: createWebHistory(),
    routes // <-- routes
})

//define route for handle authentication
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        //cek nilai dari getters isLoggedIn di module auth
        if (store.getters['auth/isLoggedIn']) {
            var user = store.getters['auth/currentUser']

            if (to.meta.isCustomer && user.login_as == 'customer') {
                if(to.meta.isKumi) {
                    if(user.is_kumi) {
                        next()
                    }else{
                        next('/')
                    }
                }else{
                    next()
                }
            }

            if (to.meta.isReseller && user.login_as == 'reseller') {
                next()
            }

            if (to.meta.isAdmin && user.login_as == 'admin') {
                if(to.meta.isKumi) {
                    if(user.is_kumi) {
                        next()
                    }else{
                        next('/admin')
                    }
                }else{
                    next()
                }
            }

            if ((to.meta.isReseller || to.meta.isAdmin) && user.login_as == 'customer') {
                next('/')
            }

            if ((to.meta.isCustomer || to.meta.isAdmin) && user.login_as == 'reseller') {
                next('/reseller/edit-profile')
            } 

            if ((to.meta.isCustomer || to.meta.isReseller) && user.login_as == 'admin') {
                next('/admin')
            }

            return
        } else {
            next('/login')
        }
        
    } else {
        next()
    }
})

export default router