<template>
    <div class="">
        <nav class="navbar navbar-white py-2">
            <div class="container">
                <div class="row w-100">
                    <div class="col-4 d-flex align-items-center">
                        <div>
                            <router-link :to="{name: 'dashboard-reseller'}" class="navbar-brand">
                                <img :src="config.theme.logo" class="img-fluid reseller-logo">
                            </router-link>
                        </div>
                        <div class="reseller-mark">
                            Reseller
                        </div>
                    </div>
                    <div class="col-3 ms-auto d-flex">
                        <div class="align-self-center w-100 text-end">
                            <div class="d-flex justify-content-end">
                                <div class="dropdown cart-dropdown">
                                    <a class="dropdown-toggle" href="#" role="button" id="cartDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        <!-- <router-link :to="{name: 'cart'}" class="btn mx-1 position-relative"> -->
                                        <img :src="Cart" />
                                        <span class="badge rounded-pill badge-cart bg-yellow text-dark"
                                            v-if="cartCount > 0">
                                            {{ cartCount }}
                                        </span>
                                        <!-- </router-link> -->
                                    </a>
                                    <div class="dropdown-menu header-dropdown dropdown-menu-center" aria-labelledby="cartDropdown">
                                        <template v-if="cartCount > 0">
                                            <div class="dropdown-new-item" v-for="item in cart" :key="'cart' + item.id">
                                                <img :src="item.product.main_image" alt="" class="dropdown-image">
                                                <div class="dropdown-title">
                                                    {{ item.product.title }}
                                                    <div class="dropdown-subtitle">
                                                        {{ item.qty }} Barang
                                                    </div>
                                                </div>
                                                <div class="cart-price">
                                                    Rp{{ moneyFormat(item.price) }}
                                                </div>
                                            </div>
                                            <router-link :to="{name: 'reseller-cart'}"
                                                class="btn btn-outline-blue btn-block mt-4">
                                                Show Cart
                                            </router-link>
                                        </template>
                                        <template v-else>
                                            <div class="my-5 text-center text-secondary">
                                                Cart is Empty
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="dropdown notification-dropdown mx-4">
                                    <a class="dropdown-toggle" href="#" role="button" id="notificationDropdown"
                                        :data-bs-toggle="authenticated ? 'dropdown' : ''" data-bs-auto-close="outside" aria-expanded="false">
                                        <img :src="Notification" />
                                        <span class="badge rounded-pill badge-notification bg-yellow text-dark">{{ notification.totalUnread }}</span>
                                    </a>
                                    <div class="dropdown-menu header-dropdown dropdown-notification" v-if="notification.notification.length > 0" style="padding-top: 0px !important;" aria-labelledby="notificationDropdown">
                                        <div class="sticky-top" style="background-color: #FFFFFF; padding: 6px 0 !important">
                                            <div class="d-flex justify-content-between">
                                                <button type="button" @click="clearNotification(null)" class="btn btn-notification btn-link">Clear Notifications</button>
                                                <button type="button" @click="markAsRead()" class="btn btn-notification btn-link">Mark All as Read</button>
                                            </div>
                                        </div>
                                       <div class="dropdown-new-item flex-column" :class="item.read_at == null ? 'unread rounded' : 'read'" v-for="item in notification.notification" :key="'notif' + item.id">
                                           <div class="w-100">
                                                <a href="javascript:void(0)" class="dropdown-title" style="color: #000000; font-weight: 700" @click="redirectNotification(item)">
                                                    <span style="font-size: 11px;">{{ item.data.title }}</span>
                                                    <div class="dropdown-subtitle">
                                                        {{ item.data.description }}
                                                    </div>
                                                </a>
                                           </div>
                                            <div class="d-flex w-100 justify-content-between">
                                                <button class="btn btn-notification btn-link" @click="clearNotification(item.id)" style="padding: 3px !important; color: #E74C3C">clear</button>
                                                <button class="btn btn-notification btn-link" @click="markAsRead(item.id)" v-if="item.read_at == null" style="padding: 3px !important; color: #3498DB">mark as read</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dropdown-menu header-dropdown" v-else aria-labelledby="notificationDropdown">
                                       <div class="dropdown-new-item">
                                            <div class="dropdown-title">
                                                <div class="my-5 text-center text-secondary">
                                                    Notification is empty
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <router-link to="" class="btn btn-outline-blue btn-block mt-4">
                                            Lihat Pemberitahuan
                                        </router-link> -->
                                    </div>
                                </div>
                                <div class="dropdown profile-dropdown">
                                    <a class="dropdown-toggle" href="#" role="button" id="profileDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <div class="user-image-initial small d-flex align-items-center justify-content-center"
                                                :style="{'background-image': 'url(' + user.avatar + ')'}">
                                                <h4 v-if="user.avatar == ''">
                                                    {{ user.name ? user.name.charAt(0) : '' }}
                                                </h4>
                                            </div>
                                            <div class="ms-2 name d-none d-md-block">
                                                {{ user.firstname }}
                                            </div>
                                        </div>
                                    </a>
                                    <div class="dropdown-menu header-dropdown dropdown-menu-end" aria-labelledby="profileDropdown">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <div class="user-image-initial d-flex align-items-center justify-content-center"
                                                :style="{'background-image': 'url(' + user.avatar + ')'}">
                                                <h4 v-if="user.avatar == ''">
                                                    {{ user.name ? user.name.charAt(0) : '' }}
                                                </h4>
                                            </div>
                                            <div class="px-3">
                                                <h5 class="mb-0 name">
                                                    {{ user.name }}
                                                </h5>
                                                <p class="mb-0">
                                                    {{ user.email }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row mt-4">
                                            <div class="col">
                                                <div class="border-end">
                                                    <h6 class="mb-3">
                                                        My Profile
                                                    </h6>
                                                    <router-link :to="{name: 'reseller-edit-profile'}" class="link">
                                                        Edit Profile
                                                    </router-link>
                                                    <router-link :to="{name: 'reseller-settings-address'}" class="link">
                                                        Address Setting
                                                    </router-link>
                                                    <router-link :to="{name: 'reseller-settings-password'}" class="link">
                                                        Password
                                                    </router-link>
                                                    <router-link :to="{name: 'reseller-wishlist'}" class="link">
                                                        Wishlist
                                                    </router-link>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div>
                                                    <h6 class="mb-3">
                                                        Payment
                                                    </h6>
                                                    <router-link :to="{name: 'reseller-order-pay'}" class="link">
                                                        Pay
                                                    </router-link>
                                                    <router-link :to="{name: 'reseller-order-process'}" class="link">
                                                        Processed
                                                    </router-link>
                                                    <router-link :to="{name: 'reseller-order-send'}" class="link">
                                                        Send
                                                    </router-link>
                                                    <router-link :to="{name: 'reseller-order-delivered'}" class="link">
                                                        Delivered
                                                    </router-link>
                                                    <router-link :to="{name: 'reseller-order-done'}" class="link">
                                                        Done
                                                    </router-link>
                                                    <router-link :to="{name: 'reseller-order-canceled'}" class="link">
                                                        Canceled
                                                    </router-link>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <a href="javascript:void(0)" class="d-flex button-logout" @click="logout()">
                                                <img :src="Logout">
                                                <div class="ms-2">
                                                    Logout
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
    import Logo from '../assets/global/logo-blue.svg';
    import Cart from '../assets/icons/cart-blue.svg';
    import Notification from '../assets/icons/notification-blue.svg';
    import Logout from '../assets/icons/logout.svg';
    import Phone from '../assets/product/phone.png';
    import Pad from '../assets/product/pad.png';
    import { useStore } from 'vuex'
    import { useRouter} from 'vue-router'
    import Swal from 'sweetalert2'

    export default {
        setup() {
            const store = useStore()
            const router = useRouter()

            //method logout
            function logout() {
                store.dispatch('auth/logout')
                    .then(() => {
                        //redirect ke dashboard
                        router.push({
                            name: 'reseller-login'
                        })
                    }).catch(err => {
                        //assign validaation message
                        Swal.fire({
                            title: 'ERROR',
                            text: err.response.data.message,
                            icon: 'error',
                            showCancelButton: true,
                            showConfirmButton: false,
                            cancelButtonText: 'Close',
                            padding: '2rem',
                        })
                    })
            }

            return {
                Logo: Logo,
                Cart: Cart,
                Notification: Notification,
                Logout: Logout,
                Phone: Phone,
                Pad: Pad,
                logout
            }
        },
        computed: {
            user() {
                var user = this.$store.getters['auth/currentUser']

                if (user.email) {
                    user.firstname = user.name.split(' ')[0]
                }

                return user
            },
            cart() {
                return this.$store.getters['cart/getCart']
            },
            cartCount() {
                return this.$store.getters['cart/cartCount']
            },
            notification() {
                return this.$store.getters['notification/getNotifications']
            },
            authenticated() {
                return this.$store.getters['auth/isLoggedIn']
            },
            config() {
                return this.$store.getters['config/getAllConfig']
            },
        },
        created() {
            if (!this.$store.getters['cart/cartStatus']) {
                this.$store.dispatch('notification/refreshNotification')
                if(this.authenticated) {
                    this.$store.dispatch('auth/getUserProfile')
                    this.$store.dispatch('cart/refreshCart')
                }
            }
        },
        methods: {
            markAsRead(notification) {
                if(!this.authenticated) {
                    this.$router.push({
                        name: 'login'
                    })
                    return false
                }
                this.$store.dispatch('notification/markAsRead', notification)
                .then(() => {
                    // Swal.fire({
                    //     title: 'Success',
                    //     text: 'Item has been successfully added to cart',
                    //     icon: 'success',
                    //     showCancelButton: true,
                    //     showConfirmButton: false,
                    //     cancelButtonText: 'Close',
                    //     padding: '2rem',
                    // })
                }).catch(err => {
                    Swal.fire({
                        title: 'ERROR',
                        text: err.message,
                        icon: 'error',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: 'Close',
                        padding: '2rem',
                    })
                })
            },
            clearNotification(notification) {
                if(!this.authenticated) {
                    this.$router.push({
                        name: 'login'
                    })
                    return false
                }
                this.$store.dispatch('notification/clearNotification', notification)
                .then(() => {
                    // this.$forceUpdate();
                    // Swal.fire({
                    //     title: 'Success',
                    //     text: 'Item has been successfully added to cart',
                    //     icon: 'success',
                    //     showCancelButton: true,
                    //     showConfirmButton: false,
                    //     cancelButtonText: 'Close',
                    //     padding: '2rem',
                    // })
                }).catch(err => {
                    Swal.fire({
                        title: 'ERROR',
                        text: err.message,
                        icon: 'error',
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: 'Close',
                        padding: '2rem',
                    })
                })
            },
        }
    }
</script>
<style lang="scss" scoped>

    .btn-notification {
        font-size: 11px;
        padding: 0px;
        font-weight: 700;
        text-decoration: none;
    }

    .unread {
        background:#EBF5FB;
    }

    .read:hover {
        background: #f8f8f8f8;
    }

    .unread:hover {
        background:#D4E6F1;
    }

    .dropdown-notification {
        max-height: 280px;
        overflow-y: auto;
    }

    .ml-top {
        margin-left: 5px;
    }

    .ig-transform {
        transform: translateY(-1px);
    }

    .btn-search-category {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: var(--white);
    }

    .btn-search {
        border-radius: 0 10px 10px 0 !important;
        background-color: var(--white);
        padding: 0 10px !important;
    }

    .btn-search img {
        transform: translateY(-2px);
    }

    .form-search {
        width: 80%;
        padding: 10px 20px;
        font-size: 12px !important;
        background-color: var(--white) !important;
    }

    .badge-cart {
        position: absolute;
        top: -5px;
        right: -7px;
    }

    .badge-notification {
        position: absolute;
        top: -5px;
        right: -7px;
    }

    .reseller-mark {
        font-size: 21px;
        margin-top: 4px;
        font-weight: lighter;
    }

    .user-image-initial {
        background-color: #FFFFFF;
        border: 1px solid var(--blue);
        text-align: center;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        flex: 50px 0 0;
        background-size: cover;
        background-position: center;
    }

    .user-image-initial.small {
        width: 30px;
        height: 30px;
        flex: 30px 0 0;
    }

    .user-image-initial h4 {
        font-weight: bold;
        font-size: 19px;
        line-height: 26px;
        color: var(--blue);
        margin-bottom: 0;
    }

    .user-image-initial.small h4 {
        font-size: 14px;
    }

    .profile-dropdown,
    .cart-dropdown,
    .notification-dropdown {
        margin-top: 6px;
    }

    .profile-dropdown .name {
        font-weight: 600;
        color: black;
        font-size: 12px;
    }

    .header-dropdown.dropdown-menu {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05) !important;
        border: none !important;
        padding: 1rem;
        width: 300px;
    }

    .profile-dropdown .dropdown-menu .link {
        font-size: 10px;
        font-weight: 400;
        color: black;
        display: block;
        margin-bottom: .5rem;
    }

    .profile-dropdown .dropdown-menu .link:hover {
        color: gray;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .button-logout {
        font-size: 12px;
        font-weight: 600;
    }

    .dropdown-new-item {
        display: flex;
        align-items: center;
        margin-bottom: .25rem;
        margin-left: -1rem;
        width: calc(100% + 2rem);
        padding: .5rem 1rem;
    }

    .dropdown-new-item .dropdown-image {
        height: 40px;
        width: 40px;
        margin-right: .5rem;
    }

    .dropdown-new-item .dropdown-title {
        font-size: 12px;
        font-weight: 600;
    }

    .dropdown-new-item .dropdown-title .dropdown-subtitle {
        font-size: 10px;
        color: grey;
        font-weight: 400;
    }

    .dropdown-new-item .cart-price {
        font-size: 10px;
        color: var(--blue);
        font-weight: 600;
        margin-left: auto;
    }

    .cart-dropdown .dropdown-menu {
        left: -175px !important;
    }

    .notification-dropdown .dropdown-menu {
        left: -217px !important;
    }

    .profile-dropdown .dropdown-menu {
        right: -12px !important;
    }

    @media screen and (max-width: 500px) {
        .form-search {
            width: 70%;
        }

        .cart-dropdown,
        .notification-dropdown {
            margin-top: 2px;

            img {
                height: 18px;
            }
        }

        .profile-dropdown {
            margin-top: 0px;
        }

        .action-header {
            margin-right: -7.5px;
        }
    }
</style>